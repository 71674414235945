<template>
    <div class="container">

         <img class="adimg" oncontextmenu="return false;" :src="`https://adgenimages.s3-us-west-1.amazonaws.com/genimages/${this.adid}.jpg`" />
    
    <div class="adfooter">
        <img  class="logo" src="../assets/AdGenie_logo.png" alt="logo" />
        <p class="poweredby">Powered by <a href="https://www.fictic.ai">AdGenie</a></p>
    </div>
</div>


</template>

<script>
import * as amplitude from '@amplitude/analytics-browser';


export default {
    name: 'ShowAdbyId',
    components: {  },
    emits:[],
    props:["adid"],
    head() {
    return {
      title: this.adid ,
      meta: [

        { property: 'og:image', content: `https://adgenimages.s3-us-west-1.amazonaws.com/genimages/${this.adid}.png` }
    ]
           }
  },
    data() {
        return {
        
        }
},
   metaInfo() {
    console.log("metaInfo in ShowAdbyId.vue");
        return {
            title: 'Some Ad ',
            meta: [
                { name: 'description', content: 'AdGenie Ad page' }
            ]
        }
   },
  

        

        // mounted() { },
        created() {console.log("created in ShowAdbyId.vue",this.adid);
        amplitude.track('AdViewed', {adid:this.adid});
        // console.log(this.$root)
        console.log("metamanager",this.$metaManager)
        // console.log(this.$root.$root)
        // console.log(this.$root.$root.metaManager)
//         useMeta(
//   {
//     title: 'some ad' } 
// )
        }
    }


</script>
<style scoped>

.adimg {
    max-width: 100%;
    height: auto;
    -webkit-touch-callout: none;
    justify-content: center;
}
.adfooter{
    margin-top:10px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.logo {
    width: 50px;
    height: auto;
}
 
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: top;
  height: 100vh; /* Adjust as needed */
}

.poweredby {
    margin-top: 14px;
    color:beige;
}
@media screen and (max-width: 1000px) {
   
    
}

@media screen and (max-width: 768px) {
   
 
}


</style>