<template>

  <head>
    <title> {{ msg }}</title>
  </head>
  <div class="homepage">

    <!-- <DynamicDialog :visible="true" /> -->



    <div class="search_group">

      <h1 class="title">{{ this.title }} </h1>
      <h2 class="subtitle">AI generated <span> {{ this.subtitle }} </span> for marketing, promos, presentations &
        more...
      </h2>


      <transition name="slide"></transition>
      <div class="top">
        <!-- <IconField class="textboxicongroup" iconPosition="right"> -->
        <InputText type="text" @keyup.enter="handleClick($event)" :disabled="this.loading" @change="handleChange"
          v-model="pText" placeholder="" class="oversized-textbox" :style="{ 'margin-left': marginLeft }" />
        <!-- <i class="fa-duotone fa-pen" v-if="this.productId.length == 36" @click="editProduct"></i> -->
        <!-- <font-awesome-icon :icon="['fas', 'pen']" /> -->

        <!-- <i class="pi pi-pencil" style="color: #60A5FA; cursor: grab;" v-if="this.productId.length == 36"
            @click="editProduct">&nbsp;<b>Edit</b></i> -->
        <!-- <div class="buttongroup"> -->
        <div v-if="!showDocUploader" :src="require('@/assets/docicon.png')" class="docicon"
          v-tooltip="'Upload a document'" style="height:40px;width:40px;"
          @click="this.showDocUploader = !this.showDocUploader">
          <img :src="require('@/assets/docicon.png')" style="height:40px;width:40px;" />
        </div>
        <div :src="require('@/assets/editbutton.png')" class="editbutton"
          v-if="this.productId.length == 36 && this.productId.includes('-') && !this.loading" @click="editProduct">
          <img :src="require('@/assets/editbutton.png')" style="height:40px;width:40px;" />
        </div>
        <Button @click="handleClick" :loading="loading" v-if="true"
          title="Enter Amazon product URL or basic details about your product or service" class="go-button-right">{{
            this.buttonlabel }}


        </Button>
        <!-- </div> -->



        <!-- </IconField> -->

        <!-- <button v-if="this.themes.length > 0" :disabled="this.selectedthemes.length == 0" :title="this.selectedthemes.length == 0 ? 'select theme to download images' : 'click to download images'"
         label="Download" class="download-button"
           @click="showDownloadPanel()"><img
            height="50px" width="50px" src="../assets/download.png" :class="this.selectedthemes.length == 0 ? 'downloadimg-disabled' : 'downloadimg'" /></button> -->
        <Panel class="docuploaderdiv" :class="{ 'drag-over': isDragOver }" ref="docuploader" :showHeader="false"
          v-if="this.showDocUploader" :style="{
            width: '100%', height: '10vh',
            display: 'flex', alignItems: 'center', justifyContent: 'center',
          }" :closeOnEscape="false" :draggable="false" :keepInViewPort="false" v-model:visible="this.showDocUploader"
          position="Left" @dragover.prevent="handleDragOver" @dragleave="handleDragLeave" @drop.prevent="handleDrop">
          <div>
            <p :style="{ width: '100%', marginTop: '50px' }"> {{ "Click Upload or Drag files here to upload" }} </p>
            <ProgressSpinner v-if="uploadInProgress" style="width: 20px; height: 20px; margin-top:10px;"
              strokeWidth="8" />
            <FileUpload :style="{ marginBottom: '30px' }" ref="fileUpload" mode="basic" name="logo"
              @select="selectDocument($event)" :multiple="false"
              :chooseLabel="this.attachedfilename == '' ? 'Upload' : this.attachedfilename"
              accept="text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/rtf,text/plain"
              :maxFileSize="this.maxFileSize" />

          </div>
        </Panel>
      </div>

      <ProgressBar v-if="!this.processComplete || this.loading" :value="this.progressvalue"></ProgressBar>

    </div>

    <!-- <h3 @click="showNAMsg" class="noamazon" :hidden="isMoved"> Not selling through Amazon? </h3> -->

    <Message :style='{ "margin-top": "50px" }' :life=3000 :closable="true" @close="closedwarning()"
      v-if="this.warnMessage.length > 0" severity="warn">{{
        this.warnMessage }}
      <div @click="this.showPricing = true" v-if="this.warnMessage.startsWith('You have exhausted your free tokens')"
        class="warning-close">
        <p>
          <a> Click here to purchase more tokens</a>
        </p>
      </div>
    </Message>
    <!-- <Button  icon="pi pi-download" class="themebutton"  /> -->

    <div class="statuspanel" v-if="!this.processComplete || this.loading">



      <Message icon=null severity="info" :closable="false">
        <div class="status-text">{{ this.statusmsg }}
          <ProgressSpinner style="width: 20px; height: 20px; margin-top:20px;" strokeWidth="8" />
        </div>
      </Message>


    </div>

    <!-- 
    <div class="filtertoggle" v-if="(this.statusimages.length > 0 || this.videoloadcount > 0)">
      <ToggleButton v-model="imagebtnstate" onLabel="Images"
        :offLabel="this.statusimages.length == 0 ? 'Images' : 'Images (' + this.statusimages.length.toString() + ')'"
        @change="filterOnImages()" />
      <ToggleButton v-model="videobtnstate" onLabel="Videos"
        :offLabel="this.videoimages.length == 0 ? 'Videos' : 'Videos (' + this.videoimages.length.toString() + ')'"
        @change="filterOnVideos()" />
    </div> -->


    <div class="postergrid" id="postergrid" v-if="this.posterimages.length > 0">
      <div v-for="(posterimage, index)  in this.posterimages" :key="index" class="poster-container">
        <div class="grid-item">
          <img :src="posterimage" />
          <div class="imageactions">

            <Button icon="pi pi-download" @click="downloadImage(posterimage, $event)" class="centered-button" />
            <!-- <img :src="require('@/assets/whatsapp.png')" :style='{"height":"3rem", "width":"3rem"}' @click="this.getWALink(statusimage)" class="centered-button" /> -->


          </div>
        </div>
      </div>
      <OverlayPanel ref="buydownload">
        <div class="download-op">
          <!-- <p> Download this Ad for ₹99</p> -->
          <Button class="buybutton" :label="'Download this Ad for ' + this.downloadprice"
            @click="purchaseDownload()"></Button>
          <!-- <img class="psupport" :src="require('@/assets/supportedpayments.png')" /> -->


        </div>
      </OverlayPanel>
    </div>

    <video id="introvideo" ref="introvideo" v-if="this.pptximages.length == 0 &&  this.videoimages.length == 0 && this.statusimages.length == 0"
      controls class="instructionvideo" @mouseover="playIntroVideo" @mouseleave="pauseIntroVideo"
      @ended="completeIntroVideo">
      <source src="https://sdgenvideos.s3.us-west-1.amazonaws.com/introvideo.mp4" type="video/mp4">
    </video>

    <div class="maingrid" id="maingrid" v-if="((this.statusimages.length > 0) && this.showimages)">


      <div v-for="(statusimage, index)  in this.statusimages" :key="index" class="image-container">
        <!-- <img src="$(n).png" > -->
        <div class="grid-item">

          <img :src="require('@/assets/watermark.png')" class="watermark" />
          <img :src="statusimage" oncontextmenu="return false;" @contextmenu.prevent style="{ pointer-events : none}">
          <!-- <a :href="statusimage" download id="download-link"> -->


          <div class="imageactions">

            <Button icon="pi pi-download" @click="downloadImage(statusimage, $event)" class="centered-button" />







          </div>
        </div>
      </div>



      <OverlayPanel ref="buydownload">
        <div class="download-op">
          <!-- <p> Download this Ad for ₹99</p> -->
          <Button class="buybutton" :label="'Download this Ad for ' + this.downloadprice"
            @click="purchaseDownload()"></Button>
          <!-- <img class="psupport" :src="require('@/assets/supportedpayments.png')" /> -->


        </div>
      </OverlayPanel>



      <div v-for="i in Math.max(6 - statusimages.length, 0)" :key="i" class="image-container">
        <div v-if="!this.processComplete || this.loading" class="grid-item">

          <img :src="require('@/assets/imageloading.png')" />
          <ProgressSpinner style="position: absolute ; width: 20px; height: 20px; margin-left:-50%; margin-top:150%;"
            strokeWidth="8" />

        </div>




      </div>

      <!--  -->







    </div>
    <OverlayPanel ref="buyvideo">
      <div class="download-op">
        <!-- <p> Download this Ad for ₹99</p> -->
        <Button class="buybutton" :label="'Download Video for ' + this.videodownloadprice + ' (Watermark Free)'"
          @click="purchaseVideo()"></Button>
        <!-- <img class="psupport" :src="require('@/assets/supportedpayments.png')" /> -->
      </div>
    </OverlayPanel>
    <div class="videocontainer" v-if="this.showvideos">


      <Button class="edit-overlay-btn"
      v-if="this.selectedVideoId !== null && this.selectedVideoId.includes('SPTMP-36S-DOC-2') && isVideoCompleted()"
      v-tooltip="'Customize the video with your own images or text'"
      @click="editVideo(this.selectedvideoindex, selectedVideoId,'overlay')">
      Edit With AI
    </Button>
      <IQFrame ref="iqFrame" :style="{ maxHeight: '70vh', minHeight: '35vh' }" v-if="this.selectedVideoId !== null"
        :videoid="this.selectedVideoId" :type="this.selectedMediaType" @videoended="endedVideo(selectedvideoindex)"
        @videopaused="pausedVideo(selectedvideoindex)" @videoplayed="playedVideo(selectedvideoindex)"
        @videoseeked="seekedVideo(selectedvideoindex)" @videoloaded="handleVideoLoaded(selectedvideoindex)" />
      <!-- <img v-if="this.selectedVideoId !== null" :src="getWaterMarkImage(this.selectedVideoId)" class="videowatermark"
        :style="{ width: this.selectedVideoId.includes('SPTMP-36S-DOC-2') ? '75%' : 'auto', height: this.selectedVideoId.includes('SPTMP-36S-DOC-2') ? 'auto' : '90%' }" /> -->
    </div>
    <Button class="edit-video-btn"
      v-if="this.selectedVideoId !== null && (this.selectedVideoId.includes('SPTMP-36S-DOC') || this.selectedVideoId.includes('SPTMP-DOC-SLIDES-1') )"
      v-tooltip="'Customize the video with your own images or text'"
      @click="editVideo(this.selectedvideoindex, selectedVideoId,'bottom')">
      Edit With AI
    </Button>

    <div id="videoactions" class="videoactions" v-if="this.selectedVideoId !== null">


      <!-- <Button icon="pi pi-play"   style="font-size: 2.5rem" @click="playVideo(this.selectedvideoindex)"
              class="play-button" /> -->
      <img icon="pi pi-download" :src="require('@/assets/downloadimg.png')" v-tooltip="this.selectedMediaType == 'pptx'? 'Download Presentation for FREE': 'Purchase & Download this video'"
        @click="downloadImage(selectedVideoId, $event)" class="download-video" />
      <!-- <Button icon="pi pi-pencil"   @click="editVideo(0, selectedVideoId)"
              :class="this.getVideoImageAspect(selectedVideoId) == 'portrait' ? 'edit-video-portrait' : 'edit-video-landscape'" /> -->

      <img  icon="pi pi-share-alt" @click="shareVideo(selectedVideoId, 0, $event)" class="share-video"
        v-tooltip="'Share this Fictic AI interactive video link with your audience for FREE'"
        :src="require('@/assets/forward.png')" />
      <!-- <img :src="this.playbutton" class="play-button" @click="playVideo(index)" /> -->

      <Message class="sharemessage" v-if="showShareMsg > -1" severity="contrast" :life="3000">Share Link
        copied to
        clipboard</Message>


    </div>

    <div :class="['nextvideooverlay', { expanded: this.overlayExpanded[this.selectedvideoindex] }]">
      <img v-if=this.overlayExpanded[this.selectedvideoindex] class="nextvideoimage" :src="firstFrameSrc"
        @click="playnextvideo(this.selectedvideoindex)" alt="Play Next Video">
      <Button v-if=this.overlayExpanded[this.selectedvideoindex] class="nextvideobutton"
        @click="playnextvideo(this.selectedvideoindex)" alt="Play Next Video">Play Next Video</Button>
    </div>


    <!-- <div v-for="(videoimage, index)  in videoimages" ref="videoitem" :key="index" class="video-item"
        @keydown="handleKeydown($event, videoimage)">
        <div class="video-wrapper">

          <img :src="getWaterMarkImage(videoimage)" class="videowatermark"
            :style="{ width: videoimage.includes('SPTMP-36S-DOC-2') ? '92%' : 'auto', height: videoimage.includes('SPTMP-36S-DOC-2') ? 'auto' : '92%' }" />
          <div :class="['nextvideooverlay', { expanded: this.overlayExpanded[index] }]">
            <img v-if=this.overlayExpanded[index] class="nextvideoimage" :src="firstFrameSrc"
              @click="playnextvideo(index)" alt="Play Next Video">
            <Button v-if=this.overlayExpanded[index] class="nextvideobutton" @click="playnextvideo(index)"
              alt="Play Next Video">Play Next Video</Button>
          </div>
          <video crossorigin="anonymous" preload="metadata" ref="videoPlayer" controls
            controlsList="nodownload noplaybackrate nopictureinpicture fullscreen" @play="playedVideo(index)"
            @ended="endedVideo(index)" @pause="pausedVideo(index)" @abort="abortedVideo(index)"
            @seeked="seekedVideo(index)" @loadeddata="handleVideoLoaded(index)" @contextmenu.prevent
            disablepictureinpicture webkit-playsInline="true" playsInline="true">

            <source :src="videoimage + '#t=0.2'" type="video/mp4">
            Your browser does not support the video tag.

          </video>

          
     </div> -->
    <!-- <div v-for="i in Math.max(this.videosToGenerate - videoloadcount, 0)" :key="i" class="image-container">
        <div v-if="((this.videoloadcount < 4) && (this.videoloading))" class="grid-item">

          <img :src="require('@/assets/videoloading.png')" />
          <ProgressSpinner style="position: absolute ; width: 20px; height: 20px; margin-left:-50%; margin-top:150%;"
            strokeWidth="8" />

        </div>
      </div> -->


    <div class="emptyMessage"
      v-if="this.videoimages.length == 0 && this.showvideos == true && this.statusimages.length > 0">
      <p> You dont have any video created yet.</p>
      <Button @click="generateBtnClicked('Video Ads')"
        :disabled="loading || (this.pText.length == 0 && this.productId.length == 0 && this.attachedfilename == '')"
        class="generatebutton"> Generate
        Videos </Button>

    </div>
    <div class="emptyMessage"
      v-if="this.statusimages.length == 0 && this.showimages == true && this.videoimages.length > 0">
      <p> You dont have any Images created yet.</p>
      <Button @click="generateBtnClicked('Image Ads')"
        :disabled="loading || (this.pText.length == 0 && this.productId.length == 0)" class="generatebutton">
        Generate Images </Button>
    </div>
    <ProgressSpinner v-if="this.refreshspinner" style="width: 30px; height: 30px; margin-top:20px;" strokeWidth="10" />

    <!-- <div class="filtertoggle" v-if="(this.statusimages.length > 0 || this.videoloadcount > 0)">
      <ToggleButton v-model="imagebtnstate" onLabel="Images"
        :offLabel="this.statusimages.length == 0 ? 'Images' : 'Images (' + this.statusimages.length.toString() + ')'"
        @change="filterOnImages()" />
      <ToggleButton v-model="videobtnstate" onLabel="Videos"
        :offLabel="this.videoimages.length == 0 ? 'Videos' : 'Videos (' + this.videoimages.length.toString() + ')'"
        @change="filterOnVideos()" />
    </div> -->

    <Button v-if="this.statusimages.length > 0" :disabled="this.loading || this.refreshspinner" @click="askMoreImages()"
      icon="pi pi-plus" class="morebutton" id="morebutton"> More </Button>







    <Dialog v-model:visible="showManualInput" modal ref="previewdialog" :showHeader="true" :style="{ width: '30vw' }"
      :closeOnEscape="false" :draggable="false" :keepInViewPort="false" position="Left">
      <template #header>
        <div class="dialogheader">
          <span class="font-bold whitespace-nowrap"> More info needed to Generate your content</span>
        </div>
      </template>
      <PreviewAd modal="true" :productText="this.pText" :productIdInput="this.productId" :fromVideoTab="this.showvideos"
        :runcounter="this.displayedPrompter" :mediaFormatPreference="this.mediaformatpreference"
        @onProductId="processManualID" @onFirstRun="adjustPreviewDialog" />



    </Dialog>

    <Dialog v-model:visible="showDocInput" modal ref="docinputdialog" :showHeader="true" :style="{ width: '50vw' }"
      :closeOnEscape="false" :draggable="false" :keepInViewPort="false" position="Left">
      <template #header>
        <div class="dialogheader">
          <span class="font-bold whitespace-nowrap"> Few more questions...</span>
        </div>
      </template>
      <DocumentInput modal="true" :inputstring="this.pText" :document="this.attachedDocument"
        @onDocumentInput="processDocumentInput" />



    </Dialog>

    <Dialog v-model:visible="this.showVideoEditor" modal header="Edit Video" :showHeader="true"
      :style="{ width: '70vw', margin: '0px', padding: '0px', border: '1px solid black', boxShadow: '0 0 10px 10px rgba(87, 85, 85, 0.5)' }"
      :closeOnEscape="false" :draggable="false" position="Left" :keepInViewPort="false">

      <!-- <template #header>
      <div class="dialogheader">
        <span class="font-bold whitespace-nowrap"> Edit Video </span>
      </div>
    </template> -->

      <EditVideo :videoId="this.editVideoId" @videosaved="loadSavedVideo" />

    </Dialog>






    <!-- <Dialog :showHeader="true" class="PricingPage" header="Pricing"
      :style="{ height: '40vh', width: '60vw' }" :closeOnEscape="true" :draggable="false" :keepInViewPort="true"
      :visible="showPricingDialog" :closable="true" modal > -->
    <Dialog header="Purchase Tokens" v-model:visible="showPricing" :closeOnEscape="false">
      <PricingPage :downloadurl="this.pendingdownloadurl" :userid="this.adgenieuserid" :userCountry="IN"
        @buybuttonclicked="downloadImage" @emailsubmitted="collectedEmailFromPricing" />

    </Dialog>

    <Dialog :style="{ width: '60vw', height: '40vh' }" v-model:visible="showPrompter" :keepInViewPort="true"
      :closeOnEscape="true" :show-header="false">
      <EditPrompter @editprompter="processPrompter" />



    </Dialog>


    <OverlayPanel ref="shareop">
      <!-- <div>
        <p color="gray"> Share this link: </p>
        <a :href="this.shareURL" @click="clickshareurl">{{ this.shareURL }} </a>
      </div> -->

      <InputGroup>
        <p color="gray"> Share this link: </p>
      </InputGroup>
      <InputGroup>
        <InputText :value="this.shareURL" readonly class="w-25rem"></InputText>

        <!-- <a :href="this.shareURL" @click="clickshareurl">{{ this.shareURL }} </a> -->
        <InputGroupAddon>
          <i class="pi pi-arrow-right" @click="copylink()" :style="{ cursor: 'pointer' }"></i>
        </InputGroupAddon>
      </InputGroup>

    </OverlayPanel>


    <Sidebar ref="sidebar" v-model:visible="this.showsidebar" header="Videos" position="right"
      :style="{ width: this.sidebarwidth }">
      <div class="videothumbnailcontainer">
        <div v-for="(videoimage, index)  in this.videoimages" ref="videoitem" :key="index" class="videothumbnail"
          @click="selectVideo(videoimage)">
          
          <video ref="previewvideo" crossorigin="anonymous" preload="metadata" muted class="previewvideo">
            <source :src="videoimage + '#t=0.2'" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <img :src= "require('@/assets/presentations.png')" v-if="this.pptximages.length > 0" class="presentationbar"  />
        <div v-for="(pptximage, index)  in this.pptximages" ref="pptxitem" :key="index" class="videothumbnail"
          @click="selectPPTX(pptximage)">

          <img :src="(this.pptxpreview.length>index) ? this.pptxpreview[index]:require('@/assets/pptxdocicon.png')" class="previewpptx" />
        </div>
      </div>
    </Sidebar>
    <div class="rightvideopane" @click="this.showsidebar = true"
      v-if="(!this.showsidebar && (this.videoimages.length > 0 || this.pptximages.length > 0))" header="Videos">
      <p class="badge">{{ this.videoimages.length }}</p>
      <p class="vtext">Videos</p>

      <img class="rightvideobutton" :src="require('@/assets/lefticon.png')" @click="clickSideBar()"
        v-tooltip="'Click to browse all videos'" />
    </div>
  </div>
















  <div class="footer">
  </div>
</template>

<script>
// import { ref } from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
// import Galleria from 'primevue/galleria';
import Panel from 'primevue/panel';
// import Tag from 'primevue/tag';
import OverlayPanel from 'primevue/overlaypanel';
import '../../node_modules/primevue/resources/themes/aura-dark-blue/theme.css';
import '../../node_modules/primevue/resources/primevue.min.css';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Dialog from 'primevue/dialog';
import * as amplitude from '@amplitude/analytics-browser';
import { event } from 'vue-gtag';
import PreviewAd from './PreviewAd.vue'
import EditVideo from './EditVideo.vue'
import PricingPage from "./PricingPage.vue"
import IQFrame from './IQFrame.vue';
// import ToggleButton from 'primevue/togglebutton';
import { v4 as uuidv4 } from 'uuid';
import FileUpload from 'primevue/fileupload';
import 'primeicons/primeicons.css';
import ProgressBar from 'primevue/progressbar';
// import IconField from 'primevue/iconfield';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import EditPrompter from './EditPrompter.vue'
import DocumentInput from './DocumentInput.vue';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Sidebar from 'primevue/sidebar';








export default {


  data() {
    return {
      pText: '',
      title: 'Create Videos from Documents or Text',
      subtitle: 'visuals, videos and slideshows',
      isMoved: false,
      loading: false,
      responsiveOptions: [],
      images: [],
      position: 'bottom',
      activeIndex: 1,
      themeimages: {},
      themes: [],
      prethemes: [],
      processComplete: true,
      statusmsg: 'Fictic AI is processing…',
      progresslevel: 0,
      statusimages: [],
      selectedthemes: [],
      jobid: null,
      // jobid: "a1f02800-61d4-456f-8950-cccb1710386e",
      warnMessage: "",
      progressvalue: 1,
      platforms: [],
      showEmailForm: false,
      email: '',
      // productId: "cfdeaede-5719-4aae-96ba-f058c70c508a",
      productId: "",
      statustimer: null,
      progresstimer: null,
      values: [],
      reviews: "",
      buttonlabel: "Generate",
      tokens: 100,
      jobcounter: 0,
      showManualInput: false,
      refreshspinner: false,
      productType: "SocialMedia",
      productName: ".",
      processManualInput: false,
      showPricing: false,
      pTextChanged: false,
      showPrompter: false,
      displayedPrompter: 0,
      pendingdownloadurl: "",
      download_utm_content: "",
      lastjobstartseconds: new Date().getTime() / 1000,
      samples: ["g1.webp", "g2.webp", "g3.webp", "e2.jpg", "e4.jpg", "p1.jpg"],
      // videoimages: [ "https://sdgenvideos.s3.us-west-1.amazonaws.com/videos/d40a80a5-51ac-4c68-a712-a309ad6d3e82-SPTMP-36S-DOC-2.mp4","https://sdgenvideos.s3.us-west-1.amazonaws.com/videos/79dbd92b-82ef-4465-bb8a-3d471cecd679-SPTMP-36S-2.mp4"],
      videoimages: [],
      pptximages: [],
      pptxpreview:[],
      videomap: {},
      userimageupload: false,
      usercountry: "IN",
      downloadprice: "₹49",
      videodownloadprice: "₹499",
      playbutton: require('@/assets/playbutton.png'),
      videoloadcount: 0,
      showimages: false,
      showvideos: true,
      editVideoId: "",
      videoloading: false,
      imagebtnstate: false,
      videobtnstate: true,
      mediaformatpreference: "Image Ads",
      playingIndex: null,
      videosToGenerate: 0,
      overlayExpanded: [false, false],
      firstFrameSrc: "",
      posterimages: [],
      mediaformat: "Both",
      showDocUploader: true,
      isDragOver: false,
      allowedMimeTypes: [
        'text/csv',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/rtf',
        'text/plain',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ],
      maxFileSize: 90000000,
      showVideoEditor: false,
      showShareMsg: -1,
      shareURL: "",
      attachedDocument: null,
      attachedfilename: "",
      uploadInProgress: false,
      selectedVideoId: null,
      selectedMediaType: "video",
      showsidebar: false,
      selectedvideoindex: 0,
      selectedpptxindex: 0,
      showDocInput: false,
      generateSlideVideo: "initialized"





      // email:""



    }
  },
  components: {
    InputText, Button, OverlayPanel, Message, ProgressSpinner, Dialog, Panel, PreviewAd, EditVideo, PricingPage, EditPrompter, IQFrame, FileUpload, ProgressBar, InputGroup, InputGroupAddon, Sidebar, DocumentInput//,DynamicDialog,Galleria, Tag,ToggleButton,
  },
  emits: ["incrementjob", "emailsubmitted", "deducttoken"],
  props: ["selectedjobid", "msg", "tokensLeft", "showPricingDialog", "adgenieuserid", "productMode"],
  computed: {
    reversedStatusImages() {
      return [...this.statusimages].reverse();
    },
    sidebarwidth() {
      if (window.innerWidth <= 768) {
        return '90vw';
      }
      else {
        return '12vw';
      }
    },
    marginLeft() {
      if (window.innerWidth <= 768 && this.productId.length == 36 && this.productId.includes('-') && !this.loading) {
        return '-60px';
      } else {
        if (this.productId.length == 36 && this.productId.includes('-') && !this.loading) {
          return '-70px';
        }
        else {
          return '0px';
        }
      }
    },
    
  },
  watch: {
    selectedjobid: {
      handler(val) {
        console.log(val)
        this.resetPage();
        this.isMoved = true;
        this.jobid = val;
        this.statusimages = []
        this.videoimages = []
        this.pptximages = []
        this.showsidebar = false;
        this.overlayExpanded = []
        // this.videoimages = ["https://sdgenvideos.s3.us-west-1.amazonaws.com/videos/582636ca-132b-4e9d-a6ef-a656c3d046ef-SPTMP-36S-DOC-2.mp4", "https://cdn.shotstack.io/au/stage/tyg8aqmav5/e483c1cc-73b0-43a8-be14-a9f18cf195b4.mp4"]
        this.overlayExpanded = []
        this.videomap = {}
        this.fetchStatus();
        this.showimages = true;
        this.showvideos = false;
        this.imagebtnstate = true;
        this.videobtnstate = false
      }
    },
    productMode: {
      handler(val) {
        this.resetPage();
        if (val != "DocToVideo" && val != "SocialMedia") {
          this.showDocUploader = false;
        }
        else {
          this.showDocUploader = true;
        }
      }
    },
    // statusimages(newVal, oldVal) {
    //   this.$nextTick(() => {
    //     this.scrollToBottom();
    //   });
    // },

    tokensLeft: {
      handler(val) {
        console.log("tokens left", val);
        // if (val < 0) {

        //   this.warnMessage = "You have exhausted your free tokens.";
        // }
      }
    },

    showPricingDialog: {
      handler(val) {
        console.log(val)
        this.showPricing = true;
      }
    }
  },
  methods: {


    resetPage() {
      this.loading = false;
      this.themes = []
      this.prethemes = []
      this.values = []
      this.reviews = ""
      this.themeimages = []
      // this.statusimages = []
      this.progresslevel = 1
      // this.videoimages = ["https://cdn.shotstack.io/au/stage/tyg8aqmav5/56484bde-0de1-4ed0-a6ef-a656c3d046ef.mp4", "https://cdn.shotstack.io/au/stage/tyg8aqmav5/e483c1cc-73b0-43a8-be14-a9f18cf195b4.mp4"]
      this.overlayExpanded = []
      this.videoimages = []
      this.pptximages = []
      this.pptxpreview=[]
      this.showsidebar = false;
      this.videomap = {}
      this.statusmsg = "Fictic AI is processing…"
      this.platforms = []
      this.selectedthemes = []
      this.buttonlabel = "Generate"
      // this.refreshspinner = false;
      this.productId = ""
      this.productName = "."
      this.processManualInput = false
      this.processDocInput = false
      this.userimageupload = false;
      this.videoloadcount = 0;
      this.videoloading = false;
      this.warnMessage = "",
        this.attachedDocument = null;
      this.attachedfilename = "";
      this.progresslevel = 0;
      this.selectedVideoId = null;
      this.selectedMediaType = "video";
      this.generateSlideVideo = "initialized";


    },
    closedwarning() {
      this.warnMessage = "";
    },
    generateUUID() {
      return uuidv4();
    },


    // processPrompter(startEditing) {
    //   this.displayedPrompter = true;
    //   this.showPrompter = false;
    //   if (startEditing) {
    //     this.editProduct();
    //   }
    //   else {
    //     this.askMoreImages()
    //   }
    // },

    captureFirstFrame(index) {


      const videos = this.$refs.iqFrame.$refs.videoplayer;
      if (videos[index] !== undefined) {
        var video = videos[index];
      }
      else {
        return
      }
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.firstFrameSrc = canvas.toDataURL('image/png');
    },

    clickPresent() {
      window.open(this.shareURL + "?mode=publish", '_blank');


    },

    copylink() {
      amplitude.track('ShareURLClicked', { "shareurl": this.shareURL });
      window.open(this.shareURL, '_blank');

    },

    getVideoImageAspect(videoimage) {
      if (videoimage !== null) {
        if (videoimage.includes("SPTMP-36S-DOC-2")) {
          return "landscape";
        }
        else {
          return "portrait";
        }
      }
      else {
        return "portrait";
      }
    },

    selectVideo(videoimage) {
      console.log("videoselected selected",videoimage)
      amplitude.track('VideoSelected', { "videoindex": this.videoimages.indexOf(videoimage), "videourl": videoimage });
      this.selectedvideoindex = this.videoimages.indexOf(videoimage);
      this.selectedVideoId = videoimage.split("/").pop().split(".")[0];
      this.selectedMediaType = "video";

      //make sidebar invisible
      this.showsidebar = false;
    },
    selectPPTX(pptximage) {
      console.log("pptx selected",pptximage)
      amplitude.track('PPTXSelected', { "pptxindex": this.pptximages.indexOf(pptximage), "videourl": pptximage });
      this.selectedpptxindex = this.pptximages.indexOf(pptximage);
      this.selectedVideoId = pptximage.split("/").pop().split(".")[0];
      this.selectedMediaType = "pptx";
      console.log("selected media",this.selectedMediaType)

      //make sidebar invisible
      this.showsidebar = false;
    },

    getWaterMarkImage(videoimage) {
      if (this.getVideoImageAspect(videoimage) == "landscape") {
        return require('@/assets/videowatermark_l.png');
      }
      else {
        return require('@/assets/videowatermark.png');
      }
    },
    playVideo(index) {

      // const videoElement = this.$refs.videoPlayer[index];
      const videoElement = document.getElementById("video");
      videoElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

      // Get the current time
      const watchedTime = videoElement.currentTime;

      if (videoElement.paused) {
        videoElement.play();
        amplitude.track('VideoPlayClicked', { "videoindex": index, "videourl": this.videoimages[index], "watchedTime": watchedTime });

        this.playbuttonsrc = require('@/assets/pausebutton.png')
      }
      else {

        videoElement.pause();
      }

    },
    handleDragOver(event) {

      event.dataTransfer.dropEffect = 'copy';
      this.isDragOver = true;
      // Add any visual feedback for drag over
    },
    handleDragLeave(event) {
      console.log(event)
      this.warnMessage = "";

      this.isDragOver = false;
      // Remove any visual feedback for drag leave
    },
    handleDrop(event) {
      const files = event.dataTransfer.files;

      const allowedFiles = Array.from(files).filter(file => this.allowedMimeTypes.includes(file.type) && file.size <= this.maxFileSize);
      if (allowedFiles.length > 0) {
        this.$refs.fileUpload.upload(allowedFiles);
      } else {
        this.warnMessage = "Unsupported or Large file. Please upload a valid document.";
        amplitude.track('UnsupportedFileDropped', { "files": files });
        return;
      }
      this.productType = "Document";
      this.selectDocument(event.dataTransfer);

    },

    isVideoCompleted() {
      const videoElement = this.$refs.iqFrame?.$refs.videoplayer;

      return videoElement && (videoElement.ended || videoElement.currentTime === videoElement.duration);
    },

    selectDocument(devent) {

      if (devent.files.length == 0) {
        amplitude.track("UnsupportedFileDropped", { "files": devent.files });
        return;
      }
      this.productType = "Document";
      event('DocumentUploaded', { "productid": this.productId, "file": devent.files[0].name });
      // amplitude.track('DocumentUploaded', { "productid": this.productId,"input":this.pText,  "file": devent.files[0].name });



      const file = devent.files[0];
      if (file) {
        this.uploadInProgress = true;

        this.uploadFile(file).then((result) => {

          this.attachedDocument = result;
          this.attachedfilename = file.name;

          this.isDragOver = false;
          if (this.pText != '') { console.log(this.pText)}
          else { this.getDocPreview() }
        }).catch((error) => { console.log("Error uploading",error) ; this.uploadInProgress = false; });


        this.isDragOver = false;

      }







    },

    getDocPreview() {

      var payload = {
        "file": this.attachedDocument,
        "filename": this.attachedfilename,

      }
      fetch("https://lpn6nofxchnwthzvlbxat5w5gi0gymii.lambda-url.us-west-1.on.aws/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(response => {
        if (!response.ok) {
          this.uploadInProgress = false;
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
      }).then(data => {

        this.uploadInProgress = false;
        amplitude.track('DocPreviewGenerated', { "llprompt": data.prompts[0], "inputtext": this.pText, "s3url": data.s3url });
        if (data.prompts.length > 0 && this.pText == '') {
          this.pText = data.prompts[0];
        }
        if (data.s3url != null) {
          this.attachedDocument = data.s3url;
        }



      }).catch(error => {
        console.log("Error getting doc preview", error);


      });


    },

    generateDocVideo() {

      if (this.attachedDocument == null) {

        this.warnMessage = "Please upload a document to generate video";
        return;
      }
      this.jobid = this.generateUUID();
      this.videoimages = [];
      this.progresstimer = setInterval(() => {
        if (this.progressvalue < 100) {
          this.progressvalue += 1;
        }
      }, 1300);
      var introvidplayer = this.$refs.introvideo;
      if (introvidplayer !== null && introvidplayer !== undefined) {
        
      
      introvidplayer.src = "https://sdgenvideos.s3.us-west-1.amazonaws.com/trainervideo.mp4"
      introvidplayer.muted = true;
      introvidplayer.play();
      }

      this.showDocUploader = false;
      this.showvideos = true;
      this.videobtnstate = true;
      this.imagebtnstate = false;
      this.showimages = false;
      this.loading = true;
      this.refreshspinner = true;
      this.processComplete = false;
      this.productType = "Document";

      this.sendDocVideoRequest("SPTMP-36S-DOC-1");
      this.sendDocVideoRequest("SPTMP-36S-DOC-2");
      this.sendDocVideoRequest("SPTMP-PPTX-1");

      if (this.generateSlideVideo == "pending") {

        this.sendDocVideoRequest("SPTMP-DOC-SLIDES-1");
        this.generateSlideVideo == "done"
    }
    if (this.generateSlideVideo == "initialized") {
      this.generateSlideVideo = "skipped"
    }

    },

    sendDocVideoRequest(templateid) {
      if (this.jobid == null) {
        this.jobid = this.generateUUID();
      }
      

      this.productId = this.attachedfilename + "_" + this.jobid.substr(this.jobid.length - 4);
      this.insertVideoJob();
      this.statusmsg = "Creating Video #1 - Summary Video ...";
      if (this.usercountry == "US") {

        var env = "prod"
        }


      var payload = {
        "file": this.attachedDocument,
        "filename": this.attachedfilename,
        "userinput": this.pText,
        "userid": this.adgenieuserid,
        "jobid": this.jobid,
        "templateid": templateid,
        "environment": env,
        "productid": this.productId
      }
      this.videosToGenerate +=1
      fetch("https://f3mpvyclzbqyplj6kviyqmjh2m0ldzfv.lambda-url.us-west-1.on.aws/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(response => {
        if (!response.ok) {
          this.videosToGenerate -= 1;

          throw new Error(`HTTP error! status: ${response.status}`);
        }

        if (this.videoimages.length >= this.videosToGenerate) {

          this.loading = false;
          this.refreshspinner = false;

          this.processComplete = true; this.warnMessage = "";
          this.isDragOver = false;
        }
        return response.json();

      }).then(data => this.processVideo(data)).catch(error => {
        console.error('There was an error!', error);


        if (this.videoimages.length >= this.videosToGenerate) {
          this.loading = false;
          this.refreshspinner = false;
          this.processComplete = true;
          this.warnMessage = "Error processing document. Please try again";

          this.isDragOver = false;
          if (this.productMode == "DocToVideo" || this.productType == "Document") {
            this.showDocUploader = true;
          }

        }

      });

    },

    encodeFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },

    playnextvideo(index) {

      this.overlayExpanded[index] = false;
      if (index < this.videoimages.length - 1) {
        this.playVideo(index + 1);
      }
      else {

        this.playVideo(0);
      }
    },


    seekedVideo(index) {
      const videoElement = this.$refs.iqFrame.$refs.videoplayer;

      if (videoElement.ended || (videoElement.currentTime == videoElement.duration)) {
        this.showsidebar = true;

      }
      else {
        this.overlayExpanded[index] = false;
      }

    },

    playedVideo(index) {



      const videoElement = this.$refs.iqFrame.$refs.videoplayer;

      // Get the current time
      const watchedTime = videoElement.currentTime;

      amplitude.track('VideoPlayed', { "videoindex": index, "videourl": this.videoimages[index], "watchedTime": watchedTime });
      this.playingIndex = index;


    },
    pausedVideo(index) {

      const videoElement = this.$refs.iqFrame.$refs.videoplayer;
      amplitude.track('VideoPaused', { "videoindex": index, "videourl": this.videoimages[index] });


      // Get the current time
      const watchedTime = videoElement.currentTime;

      amplitude.track('VideoPaused', { "videoindex": index, "videourl": this.videoimages[index], "watchedTime": watchedTime });
      this.playingIndex = null;

    },
    endedVideo(index) {
      const videoElement = this.$refs.iqFrame.$refs.videoplayer;
      // Get the current time
      const watchedTime = videoElement.currentTime;
      amplitude.track('VideoCompleted', { "videoindex": index, "videourl": this.videoimages[index], "watchedTime": watchedTime });
      event('VideoCompleted', { "videoURL": this.videoimages[index], "productid": this.productId })
      this.playingIndex = null;
      if (videoElement.ended || (videoElement.currentTime == videoElement.duration)) {

        this.showsidebar = true;
      }

    },
    abortedVideo(index) {

      const videoElement = this.$refs.iqFrame.$refs.videoplayer;
      // Get the current time
      const watchedTime = videoElement.currentTime;
      amplitude.track('VideoAborted', { "videoindex": index, "videourl": this.videoimages[index], "watchedTime": watchedTime });


    },
    shareVideo(videoimage, index, eventd) {
      var videoid = videoimage.split("/").pop().split(".")[0]

      var ficticshareurl = "https://www.fictic.ai/vidid/" + videoid
      amplitude.track('VideoShared', { "videourl": this.selectedVideoId, "type": this.selectedMediaType });
      event('VideoShared', { "videoURL": videoimage, "productid": this.productId })
      if (!videoimage.includes("SPTMP")){
        alert("Powerpoints are not sharable yet. You can download it for Free though.")
        return
      }

      const shareData = {
        title: 'Fictic AI Generated Video',
        text: 'Check out this amazing video created using Fictic AI',
        url: ficticshareurl,
      }


      navigator.clipboard.writeText(ficticshareurl).then(() => {
        this.showShareMsg = index;
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });

      this.shareURL = ficticshareurl

      // this.clickPresent();

      if (navigator.canShare && navigator.canShare(shareData)) {
        this.$refs.shareop.toggle(eventd);
        // navigator.share(shareData)
        //   .then(() => console.log('Successful share'))
        //   .catch((error) => console.log('Error sharing', error));
      } else {

        this.$refs.shareop.toggle(eventd);
      }

    },


    editVideo(index, videoimage, clicktarget) {
      amplitude.track('EditVideoClicked', { "videourl": videoimage }, { "clicktarget": clicktarget });

      var videoid = videoimage.split("/").pop().split(".")[0]

      this.editVideoId = videoid
      event("Edit Video", { videoId: this.editVideoId });
      this.showVideoEditor = true;
    },

    loadSavedVideo(savedvideo) {

      this.showVideoEditor = false;
      if (savedvideo !== undefined) {

        amplitude.track('EditedVideoGenerated', { video: savedvideo });


        // refresh page with new video
        const timestamp = new Date().getTime();
        const newUrl = `${savedvideo}?t=${timestamp}`;

        var player=this.$refs.iqFrame.$refs.videoplayer 
        player.src = newUrl;
        player.load();
          
        
        //refresh video player with new video
        this.$nextTick(() => {

        });



      }
    },

    handleKeydown(event, videoimage) {
      if (event.ctrlKey && event.key === 'g') {


        var videoid = videoimage.split("/").pop().split(".")[0]

        this.editVideoId = videoid
        this.showVideoEditor = true;
      }
    },

    generateBtnClicked(mediaformat) {
      this.showManualInput = true;
      this.mediaformatpreference = mediaformat;
      amplitude.track('GenerateBtnClicked', { "mediaformat": mediaformat, "videotab": this.showvideos, "imagetab": this.showimages });
      this.handleClick();
    },

    clickSideBar() {
      amplitude.track('SidebarClicked', { "videocount": this.videoimages.length });
      this.showsidebar = true;
    },

    filterOnImages() {
      amplitude.track('FilterOnImages', { "showimages": this.showimages, "showvideos": this.showvideos });
      if (this.productMode == "DocToVideo") {

        return;

      }
      if (!this.imagebtnstate || !this.showimages) {
        this.showimages = true
        this.showvideos = false
        this.imagebtnstate = true
        this.videobtnstate = false
        return
      }

    },
    filterOnVideos() {
      amplitude.track('FilterOnVideos', { "showimages": this.showimages, "showvideos": this.showvideos });
      if (!this.videobtnstate || !this.showvideos) {

        this.showvideos = true
        this.showimages = false
        this.videobtnstate = true
        this.imagebtnstate = false

        return
      }

    },

    handleVideoLoaded() {

      this.videoloadcount += 1
      if (this.videoloadcount == 2) {
        this.videoloading = false;
      }
      // if (this.showvideos) {
      //   this.scrollToBottom();
      // }
    },

    askMoreImages() {

      amplitude.track('AskMoreImages', { "themes": this.themes, "productId": this.productId, "tokensLeft": this.tokensLeft });
      if ((this.displayedPrompter < 2) && (this.productId !== undefined && this.productId.length == 36 && this.productId.includes('-'))) {
        this.showManualInput = true;
        this.displayedPrompter += 1;
        return;
      }

      if (this.videobtnstate) {
        this.videosToGenerate += 2;
        this.videoloading = true;
        var firsttemplate = ""
        var secondtemplate = ""

        if (this.productType == "Product") {
          firsttemplate = this.getRandomValueFromList(["SPTMP-PRODUCT-18S-1", "SPTMP-PRODUCT-18S-2", "SPTMP-PRODUCT-18S-3", "SPTMP-PRODUCT-18S-4"])
          this.getVideo(firsttemplate, true)
          secondtemplate = this.getRandomValueFromList(["SPTMP-PRODUCT-15S-1", "SPTMP-PRODUCT-15S-2"])
          this.getVideo(secondtemplate, true)
        }

        firsttemplate = this.getRandomValueFromList(["SPTMP-36S-1", "SPTMP-36S-2"])
        this.getVideo(firsttemplate, true)
        secondtemplate = this.getRandomValueFromList(["SPTMP-18S-2", "SPTMP-18S-3"])
        this.getVideo(secondtemplate, true)

      }

      // this.$emit('deducttoken', 20);



      // if (this.tokensLeft <= -40) {
      //   amplitude.track('TokenExhausted', { "tokens": this.tokensLeft,"userid":this.adgenieuserid });
      //   event('TokenExhausted', {"tokens": this.tokensLeft,"userid":this.adgenieuserid })
      //   this.warnMessage = "You have exhausted your free tokens. Please purchase more tokens to continue using Fictic";
      //   this.pendingdownloadurl = "https://dummyimage"
      //   this.showPricing = true;

      //   return;

      // }

      if (this.themes.length > 0) {
        var selectedTheme = this.themes[Math.floor(Math.random() * this.themes.length)]

        this.refreshthemeimages(selectedTheme)
      }
      else {

        this.handleClick();
      }
    },
    collectedEmailFromPricing(email) {

      this.showPricing = false;
      console.log("email", email);
      this.$emit("emailsubmitted");
    },

    getRandomValueFromList(list) {
      const randomIndex = Math.floor(Math.random() * list.length);
      return list[randomIndex];
    },

    processDocumentInput(objective,audience,aspect) {
      console.log(aspect)
      this.processDocInput = true;
      this.showDocInput = false;
      // this.pText = objective + " for " + audience 
      this.handleClick();
    },

    processManualID(productId, productname, producttype, productimage, mediaformat) {

      if (productId != null && productId != undefined && productId != "") {
        if (productId != this.productId) {
          this.statusimages = []

          this.videoimages = []
          this.overlayExpanded = []
          // this.videoimages = ["https://cdn.shotstack.io/au/stage/tyg8aqmav5/56484bde-0de1-4ed0-a6ef-a656c3d046ef.mp4", "https://cdn.shotstack.io/au/stage/tyg8aqmav5/e483c1cc-73b0-43a8-be14-a9f18cf195b4.mp4"]

        }
        this.productId = productId;
      }
      this.showManualInput = false;
      this.pText = productname
      this.productName = productname
      this.productType = producttype
      this.processManualInput = true;
      this.mediaformat = mediaformat;

      if (mediaformat == "Image Ads" || mediaformat == "Both") {
        this.showimages = true;
        this.showvideos = false;
        this.imagebtnstate = true;
        this.videobtnstate = false;
      }
      if (mediaformat == "Video Ads") {
        this.showimages = false;
        this.showvideos = true;
        this.imagebtnstate = false;
        this.videobtnstate = true;
      }




      if (productimage !== undefined && productimage !== null) {
        this.userimageupload = true;
      }
      else {
        this.userimageupload = false;
      }
      this.handleClick();
    },
    scrollToBottom() {

      const gridElement = document.querySelector('#videoactions');

      if (gridElement) {

        gridElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    },

    adjustPreviewDialog() {
      
      const dialogobject = this.$refs.previewdialog.container
      console.log(dialogobject)
      // dialogobject.style.height = "35vw"


    },

    getWALink(imageurl) {
      amplitude.track('WhatsappLinkClicked', { "imageurl": imageurl });

      var imageid = imageurl.split("/").pop().split(".")[0]
      fetch("https://un57kyuvuqra5orfd6xq632eyq0eovim.lambda-url.us-west-1.on.aws/" + imageid).then(response => console.log("fetched jpg image", response)) // Initiate png to jpg conversion
      var url = "https://wa.me?text=https://fictic.co/" + imageid;
      var win = window.open(url, '_blank');
      win.blur();
      window.focus();
      return url;
    },

    editProduct() {
      amplitude.track('EditProductClicked', { "productid": this.productId });

      this.showManualInput = true;
    },
    attachButtonClicked() {
      amplitude.track('AttachButtonClicked', { "productid": this.productId });
      this.showDocUploader = !this.showDocUploader
    },

    async handleClick() {
      amplitude.track('CreateBtnClicked', { "buttonMode": this.buttonlabel, "productinput": this.pText, "tokensLeft": this.tokensLeft });

      if (this.productMode == "DocToVideo" || this.productType == "Document" || this.attachedDocument != null) {
        if (this.attachedDocument == null) {
          this.warnMessage = "Please enter your request and  Upload a Document to generate video"
          return;
        }
        if (this.attachedDocument != null && this.pText != "" && !this.processDocInput) {

          this.showDocInput = true;
          this.processDocumentInput(this.pText, "", "");
          return
        }
        if (this.attachedDocument != null && this.pText != "" && this.processDocInput) {

          this.generateDocVideo();
          return
        }
      }
      if (this.attachedDocument !== null && this.pText == "") {
        this.warnMessage = "Briefly describe the objective of this video and click Generate"

        return;
      }

      this.warnMessage = "";
      this.lastjobstartseconds = new Date().getTime() / 1000

      console.log("product id in handle click", this.productId)


      // #region Posters
      if (this.pText.includes("eventbrite.com")) {

        var eventname = this.pText.split("/").pop()
        eventname = eventname.split("?")[0]
        this.refreshspinner = true;
        this.loading = true;
        this.isMoved = true;
        this.showimages = true;
        this.showvideos = false;
        this.statusimages = []
        this.videoimages = []
        this.posterimages = []
        this.imagebtnstate = true
        this.videobtnstate = false

        this.getPoster(eventname, "k4qoBVDygvoLZzN0gj")
        this.getPoster(eventname, "V4WN6JDx37yN53Gqjk")




        return
      }
      // #endregion

      console.log(this.pText, this.productName)
      if (this.pText.includes('amazon') || (this.pText.length == 10 && this.pText.startsWith('B'))) {
        this.userimageupload = true;
      }


      if ((this.pText.toLowerCase().includes('document')) && (this.pText.toLowerCase().includes('video'))) {
        this.showDocUploader = true;

        console.log(this.$refs.docuploader)

        return;

      }
      console.log("ptext changed", this.pTextChanged, this.processManualInput)
      if (!this.processManualInput && this.pTextChanged && !this.processDocInput) {
        this.statusimages = []
        this.videoimages = []
        console.log("Changing product")
        this.resetPage();
      }
      else if (this.processDocInput) {
        this.statusimages = []
        this.videoimages = []
        this.resetPage();
      }
      else {
        this.themes = []
        this.themeimages = []
        // this.statusimages = []
        this.processManualInput = false;
        this.processDocInput = false;
        this.progresslevel = 1
        this.pTextChanged = false;
      }

      // #region Parsing Input
      console.log(this.pText, this.productName)
      if (!((this.pText.length == 36 && this.pText.includes('-')) || (this.productId.length == 36 && this.productId.includes('-') && this.productName == this.pText) || (this.pText.length == 10 && this.pText.startsWith('B')))) {
        // this.productId="df79e8c0-3215-4de4-8ef2-b2cf0242d959"
        this.ProgressSpinner = true;
        this.loading = true;


        const resp = await fetch("https://yfwxdndifb2hyomu3ohz4tw4ny0pkzto.lambda-url.us-west-1.on.aws/", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ "productInput": this.pText, "userid": this.adgenieuserid })
        }).then(response => response.json()).then(data => {
          console.log(data)
          console.log("Data succeeded")


          if (data.productId.length > 0) { if (this.productId != data.productId) { this.statusimages = []; this.videoimages = []; } this.productId = data.productId; }
          this.ProgressSpinner = false;
          this.refreshspinner = false;
          this.loading = false;
          if (data.requestDetails != null && data.requestDetails.requestType !== undefined && data.requestDetails.requestType !== null) {
            amplitude.track('Request Parsed', { "productid": this.productId, "productname": this.productName, "userid": this.adgenieuserid, "requestType": data.requestDetails.requestType, "type": data.requestDetails.type, "preferredoutput": data.requestDetails.outputFormat });
            if (data.requestDetails.requestType == "Junk") {
              this.warnMessage = "We do not have enough information to generate videos for your request";
              this.productId = "";
              return false;
            }
            if (data.requestDetails.requestType == "SocialMedia" || data.requestDetails.requestType == "Information_Script" || data.requestDetails.requestType == "Information_Topic") {

              this.showimages = false;
              this.showvideos = true;
              this.imagebtnstate = false;
              this.videobtnstate = true;
              this.mediaformatpreference = "Video Ads"
              this.productType = "SocialMedia"
              this.showManualInput = true;

              return false;
            }
            if (data.requestDetails.requestType == "Poster") {
              this.warnMessage = "Poster generation functionality is coming soon. Stay tuned!";
              this.productId = "";
              return false;
            }
            if (data.requestDetails.requestType == "Logo") {
              this.warnMessage = "Logo generation functionality is coming soon. Stay tuned!";
              this.productId = "";
              return false;
            }
            if (data.requestDetails.requestType == "Prompt") {
              this.warnMessage = "Script based generation functionality is coming soon. Stay tuned!";
              this.productId = "";
              return false;
            }
            if (data.requestDetails.requestType == "Entertainment" || data.requestDetails.requestType == "Entertainment_Topic" || data.requestDetails.requestType == "Entertainment_Script") {
              this.warnMessage = "These video topics cannot be generated yet. Stay tuned!";
              this.productId = "";
              return false;
            }
            // if (data.requestDetails.requestType == "Information") {
            //   if (data.requestDetails.type === undefined || data.requestDetails.type == "Others") {
            //     this.warnMessage = "Information To Image/Video functionality is coming soon. Stay tuned!";
            //     this.productId = "";
            //     return false;
            //   }
            //   else {
            //     this.showManualInput = true;
            //     return true;
            //   }
            // }
            if (data.requestDetails.requestType == "Document") {
              this.showDocUploader = true;

              return false;
            }
            if (data.requestDetails.requestType == "Others") {
              if (data.requestDetails.type === undefined || data.requestDetails.type == null || data.requestDetails.type == "Other") {
                this.warnMessage = "Can you add more details to your request? ";
                this.productId = "";
                return false;
              }
              else {
                this.warnMessage = "Can you add more details to your request? ";
                this.productId = "";
                return false;
              }


            }
            if (data.requestDetails.requestType == "Ads") {
              if (data.requestDetails.outputFormat == "Image") {
                this.showimages = true;
                this.showvideos = false;
                this.imagebtnstate = true;
                this.videobtnstate = false;
                this.mediaformatpreference = "Image Ads"
              }
              else {
                this.showimages = false;
                this.showvideos = true;
                this.imagebtnstate = false;
                this.videobtnstate = true;
                this.mediaformatpreference = "Video Ads"
              }
            }

            if ((data.requestDetails.requestType == "URL" && data.requestDetails.productinput.includes('amazon.')) || (data.requestDetails.website != null && data.requestDetails.website != undefined && data.requestDetails.website != "" && data.requestDetails.website.includes('amazon') && data.requestDetails.website.includes('/dp/B'))) {

              const regexGlobal = /(?:\/dp\/|$)([A-Z0-9]{10})/g;
              var matchesAll = []
              if (data.requestDetails.website !== undefined && data.requestDetails.website !== null && data.requestDetails.website !== "") {
                matchesAll = Array.from(data.requestDetails.website.matchAll(regexGlobal));
              }
              if (data.requestDetails.productinput !== undefined && data.requestDetails.productinput !== null && data.requestDetails.productinput !== "") {
                matchesAll = Array.from(data.requestDetails.productinput.matchAll(regexGlobal));
              }
              if (matchesAll.length > 0 && matchesAll[0].length > 1) {
                var pname = matchesAll[0][1]
              }
              this.pText = pname

              this.pText = pname
              this.productId = pname;
              this.processManualInput = true;
              if (data.requestDetails.outputFormat == "Image") {
                this.mediaformat = "Image Ads";
              }
              else if (data.requestDetails.outputFormat == "Video") {
                this.mediaformat = "Video Ads";
              }
              else {
                this.mediaformat = "Both";
              }

              return true;

            }
            else {
              this.showManualInput = true;
              return false;

            }



          }




        })

        var result = await resp
        console.log(result)

        if ((resp === undefined || resp === null || resp === "" || !resp) && !this.showManualInput && this.productId != "") {

          if (this.warnMessage.length == 0) {
            this.warnMessage = "We do not have enough information to generate videos for your request";
          }
          this.productId = "";


        }
        if (!(this.productId.startsWith("B0") && this.productId.length == 10)) {
          return;
        }

      }
      // #endregion




      if (this.productId == null || this.productId == undefined || this.productId == "") {

        this.productId = this.pText;
      }

      event('CreateBtnClicked', { "buttonMode": this.buttonlabel, "productinput": this.pText })






      this.loading = true;
      this.refreshspinner = true;
      this.progresstimer = setInterval(() => {
        if (this.progressvalue < 100) {
          this.progressvalue += 1;
        }
      }, 1300);
      var introvidplayer = this.$refs.introvideo;
      if (introvidplayer !== undefined && introvidplayer !== null) {
        introvidplayer.play();
      }
      this.warnMessage = "";
      this.processComplete = false;
      this.jobid = null
      setTimeout(() => {
        this.loading = false;
        this.processComplete = true;
      }, 1000000);
      this.statustimer = setInterval(() => {
        this.updateStatusonTimer();
      }, 5000);



      this.isMoved = true;


      if ((this.mediaformat == "Both" || this.mediaformat == "Image Ads") && this.productId != "") {
        this.jobid = await this.scheduleJob();
      }

      // #region Video Ads
      if ((this.mediaformat == "Both" || this.mediaformat == "Video Ads") && this.productId != "") {
        if (this.jobid == null) {
          this.jobid = this.generateUUID();
          this.insertVideoJob();
          amplitude.track('JobScheduled', { "productid": this.productId, "jobid": this.jobId, "tokensLeft": this.tokensLeft, "userid": this.adgenieuserid, "jobType": "videos" });

        }
        this.videoloading = true;
        var firsttemplate = ""
        var secondtemplate = ""
        var thirdtemplate = ""
        var fourthtemplate = ""

        if (this.productType == "Product") {
          firsttemplate = this.getRandomValueFromList(["SPTMP-PRODUCT-15S-1", "SPTMP-PRODUCT-15S-2"])
          this.getVideo(firsttemplate, true)
          secondtemplate = this.getRandomValueFromList(["SPTMP-PRODUCT-18S-1", "SPTMP-PRODUCT-18S-3", "SPTMP-PRODUCT-18S-5"])
          this.getVideo(secondtemplate, true)
          thirdtemplate = this.getRandomValueFromList(["SPTMP-PRODUCT-18S-2", "SPTMP-PRODUCT-18S-4", "SPTMP-PRODUCT-18S-6"])
          this.getVideo(thirdtemplate, true)
          fourthtemplate = this.getRandomValueFromList(["SPTMP-PRODUCT-15S-1", "SPTMP-PRODUCT-15S-2"])
          this.getVideo(fourthtemplate, true)
        }
        else if (this.productType == "SocialMedia") {
          // this.videosToGenerate = 2;
          firsttemplate = "SPTMP-SOCIAL-36S-1"
          this.getVideo(firsttemplate, true)
          secondtemplate = "SPTMP-SOCIAL-36S-2"
          this.getVideo(secondtemplate, true)

        }
        else {
          firsttemplate = this.getRandomValueFromList(["SPTMP-36S-1", "SPTMP-36S-2"])
          this.getVideo(firsttemplate, true)
          secondtemplate = this.getRandomValueFromList(["SPTMP-18S-1", "SPTMP-18S-3", "SPTMP-18S-5"])
          this.getVideo(secondtemplate, true)
          thirdtemplate = this.getRandomValueFromList(["SPTMP-18S-2", "SPTMP-18S-4", "SPTMP-18S-6"])
          this.getVideo(thirdtemplate, true)
          fourthtemplate = this.getRandomValueFromList(["SPTMP-15S-1", "SPTMP-15S-2"])
          this.getVideo(fourthtemplate, true)
        }


      }
      // #endregion 


      if ((typeof this.jobid === 'undefined') || (this.jobid == null)) {

        this.loading = false;
        this.processComplete = true;
        this.warnMessage = "Enter Amazon product URL or basic details about your product or service";
      }
      else {
        this.fetchStatus();
        this.startStatusPolling();
      }

    },

    handleChange(event) {
      console.log(event.target.value)
      this.pTextChanged = true;
      this.buttonlabel = "Generate"
    },
    async updateStatusonTimer() {

      var pname = ""
      if (this.pText.includes('amazon.')) {
        const regexGlobal = /(?:\/dp\/|$)([A-Z0-9]{10})/g;
        var matchesAll = Array.from(this.pText.matchAll(regexGlobal));
        if (matchesAll.length > 0 && matchesAll[0].length > 1) {
          pname = matchesAll[0][1]
        }
        else {
          pname = "your product"
        }
      }
      else {
        pname = this.pText
      }
      if (pname == "") {
        pname = "product/service"
      }
      if (this.productType == "SocialMedia") {
        this.statusmsg = "Generating Videos for " + pname
        return
      }

      switch (this.progresslevel) {
        case 0:

          this.statusmsg = "Fictic AI is processing…"
          this.progresslevel += 1
          break;
        case 1:
          this.statusmsg = "Analyzing details about   " + pname
          this.progresslevel += 1
          break;
        case 2:
          this.statusmsg = "Generating Images & Videos..."
          this.progresslevel += 1
          break;
        case 3:
          this.statusmsg = "Generating Images & Videos..."
          this.progresslevel += 1
          break;
        case 4:
          if (this.productType == "Service") {
            this.statusmsg = "Finding Key features for " + pname
          }
          else {
            this.statusmsg = "Finding Key features"
          }
          this.progresslevel += 1
          break;
        case 5:
          if (this.productType == "Service" || this.productType == "Product") {
            this.statusmsg = "Analyzing Customer benefits"
          }
          else {
            this.statusmsg = "Analyzing Content for " + pname
          }
          this.progresslevel += 1
          break;
        case 6:
          this.statusmsg = "Developing Value Propositions for " + pname
          this.progresslevel += 1
          break;
        case 7:
          if (this.values.length > 0) {
            this.statusmsg = "Value proposition 1: " + this.values[0].split(".")[0]
            this.progresslevel += 1

          }
          break;
        case 8:
          if (this.values.length > 1) {
            this.statusmsg = "Value proposition 2: " + this.values[1]
            this.progresslevel += 1

          }
          else {
            this.progresslevel += 1
          }
          break;
        case 9:
          this.statusmsg = "Creating Campaign Themes"
          this.progresslevel += 1
          break;
        case 10:
          if (this.prethemes.length > 0) {
            this.statusmsg = "Marketing Theme 1: " + this.prethemes[0]
            this.progresslevel += 1
          }
          break;
        case 11:
          if (this.prethemes.length > 1) {
            this.statusmsg = "Marketing Theme 2: " + this.prethemes[1]
            this.progresslevel += 1
          }
          break;
        default:


      }




    },

    setup(props) {
      this.jobid = props.selectedjobid;
      this.tokens = props.tokensLeft;

    },

    getDocImages(finalUrl){
      var url = "https://2oo27y36xibh36oqycln2zpqru0eezfr.lambda-url.us-west-1.on.aws/"
      var payload = {"fileurl":finalUrl,"extract_images":true}
      var request={
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
      fetch(url,request).then(response => response.json()).then(data => {

        var pagecount=0
        data.forEach(item => {
          if (item.pageimage !== "undefined" && item.pageimage !== null && item.pageimage !== "") {

            pagecount+=1
            return
          }
        })
        if (this.generateSlideVideo == "initialized" && pagecount>0) {
           this.generateSlideVideo="pending"
        }
        if (this.generateSlideVideo == "skipped" && pagecount>0) {
           
        
    
          this.sendDocVideoRequest("SPTMP-DOC-SLIDES-1");
          
          this.generateSlideVideo="done"
        }
            
       
        
      })

    },

    changejobid() {
      // console.log("jobid changed to",jobid)
      this.resetPage();
      this.isMoved = true;
      this.jobid = this.selectedjobid;

      this.fetchStatus();
    },
    checkedTheme(event) {
      amplitude.track('ThemeSelected', { "theme": event.value, "productId": this.productId });



    },
    purchaseDownload() {
      event('PurchaseDownloadClicked', { "userid": this.adgenieuserid });
      amplitude.track('PurchaseDownloadClicked', { "downloadurl": this.download_utm_content });
      if (this.usercountry == "IN") {
        window.open("https://checkout.acuit.ai/b/14kbK88LS3pxaDC28f?utm_content=" + this.download_utm_content, "_blank")
      }
      else {
        window.open("https://checkout.acuit.ai/b/28o15uaU0aRZ276cMU?utm_content=" + this.download_utm_content, "_blank")

      }




      // window.open("https://checkout.acuit.ai/b/test_5kA5mQ8jC6gX1sQ7ss?utm_content="+this.download_utm_content, "_blank") // for testing


    },

    downloadPresentation(pptxid) {
      var pptxurl=`https://sdgenvideos.s3.us-west-1.amazonaws.com/presentations/${pptxid}.pptx`;
      const link = document.createElement('a');
      link.href = pptxurl;
      link.download = pptxurl.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    purchaseVideo() {
      event('PurchaseDownloadVideoClicked', { "userid": this.adgenieuserid });
      amplitude.track('PurchaseDownloadVideoClicked', { "downloadurl": this.download_utm_content });

      if (this.usercountry == "IN") {
        window.open("https://checkout.acuit.ai/b/eVaaG42nu6BJbHG8wH?utm_content=" + this.download_utm_content, "_blank")
      }
      else {
        // window.open("https://checkout.acuit.ai/b/aEU4hGe6ce4bdPO4gp?utm_content=" + this.download_utm_content, "_blank")
        window.open("https://checkout.acuit.ai/b/7sIbK8gek5xFcLK9AN?utm_content=" + this.download_utm_content, "_blank") //discounted

      }





      // window.open("https://checkout.acuit.ai/b/test_5kA5mQ8jC6gX1sQ7ss?utm_content="+this.download_utm_content, "_blank") // for testing


    },


    processData(data) {
      if (data.errors != null) {

        this.warnMessage = "Unable to generate images for this product. Please retry later";
        this.processComplete = true;
        this.refreshspinner = false;


        if (data.images != null) {

          data.images.forEach(item => {
            if (!this.statusimages.includes(item)) {
              this.statusimages.push(item);
              if (this.showimages) {
                this.scrollToBottom();
              }
              if (this.statusimages.length == 1) { amplitude.track('FirstImageLoaded', {}) }
              amplitude.track('ImageGenerated', { "imageURL": item, "productid": this.productId, 'timetaken': new Date().getTime() / 1000 - this.lastjobstartseconds });
              event('ImageGenerated', { "imageURL": item, "productid": this.productId })
            }
          })
        }
        amplitude.track('ErrorGenerating', { "error": data.errors, "ShownImagesSoFar": this.statusimages.length });
        return
      }
      if (data.output != null) {

        clearInterval(this.statustimer);
        this.processOutput(data.output);
        this.processComplete = true;
        this.refreshspinner = false;


      }
      else {
        if (this.progresslevel > 11) { this.statusmsg = "Your content is being generated"; }


        if (data.images != null) {

          data.images.forEach(item => {
            if (!this.statusimages.includes(item)) {
              this.statusimages.push(item);
              if (this.showimages) {
                this.scrollToBottom();
              }
              amplitude.track('ImageGenerated', { "imageURL": item, "productid": this.productId });
              event('ImageGenerated', { "imageURL": item, "productid": this.productId })
            }
          });

          // this.statusimages = data.images;
          // console.log(this.statusimages)
        }
        if (data.themes != null) {
          this.prethemes = data.themes.split("|");
          if (data.values != null) {
            this.values = data.values.split("|");
          }
          if (data.reviews != null) {
            this.reviews = data.reviews;
          }

          // console.log(this.themes)
        }

      }
    },

    selectJob(jobid) {
      this.jobid = jobid;
      this.fetchStatus();
    },

    startStatusPolling() {

      const intervalDelay = 5000; // 5 seconds
      const timeoutDuration = 1000000; // 120 seconds

      let elapsedTime = 0;
      this.timeoutId = setTimeout(() => {
        clearInterval(this.intervalId);
      }, timeoutDuration);

      this.intervalId = setInterval(() => {
        elapsedTime += intervalDelay;
        if (this.processComplete || elapsedTime >= timeoutDuration) {

          this.processComplete = true;
          this.loading = false;
          this.refreshspinner = false;
          clearInterval(this.intervalId);
          clearTimeout(this.timeoutId);
          clearInterval(this.statustimer);
          clearInterval(this.progresstimer);
        } else {
          this.fetchStatus();
        }
      }, intervalDelay);
    },


    download() {
      this.$refs.opd.toggle();
      amplitude.track('Download', { "selectedthemes": this.platforms });
      event('Download', { "selectedthemes": this.platforms });
      if (this.email == "") {
        this.showEmailForm = true;
      }




      var downloadlist = []



      for (let theme of this.selectedthemes) {
        for (let image in this.themeimages[theme]) {
          downloadlist.push(this.themeimages[theme][image].itemImageSrc);
        }
      }

      this.downloadImagesAsZip(downloadlist);

    },

    deductTokens() {
      var useridparam = ""
      try {
        if (!(this.adgenieuserid == null || this.adgenieuserid == undefined || this.adgenieuserid == "")) {
          useridparam = "&userid=" + this.adgenieuserid

        }

        fetch("https://tv2fr6ulib.execute-api.us-west-1.amazonaws.com/prod/addtoken?tokenstoadd=-10&jscall" + useridparam)
          .then()
      } catch (error) {
        console.error('There was an error deducting tokens', error)
      }
    },

    async downloadImagesAsZip(imageUrls) {
      const zip = new JSZip();
      const imgFolder = zip.folder("images");


      // Loop over each image URL, fetch the image, and add it to the zip
      const imagePromises = imageUrls.map(async (url, index) => {

        const response = await fetch(url);
        const blob = await response.blob();
        imgFolder.file(`image${index + 1}.${blob.type.split('/')[1]}`, blob, { binary: true });
      });

      // Wait for all images to be added
      await Promise.all(imagePromises);



      // Generate zip file and trigger download
      zip.generateAsync({ type: "blob" }).then(function (content) {
        // Use FileSaver.js or a similar method to save the file
        saveAs(content, "images.zip");
      });
    },




    async downloadImage(imageUrl, eventd, fromPricing = false) {

      if (imageUrl.includes(".pptx") || this.selectedMediaType == "pptx") {
        amplitude.track('DownloadPresentation', { "imageUrl": imageUrl, "productid": this.productId });
        this.downloadPresentation(imageUrl) //this is pptx id rather than url
        return
      }

      console.log(fromPricing)
      this.pendingdownloadurl = imageUrl;
      event('Download', { "userid": this.adgenieuserid, "productid": this.productId, "item": imageUrl });


      // if (this.tokensLeft <= 50 && !fromPricing) {
      if (this.tokensLeft > -100000) {
        amplitude.track('TokenExhausted', { "tokens": this.tokensLeft, "userid": this.adgenieuserid });
        event('TokenExhausted', { "tokens": this.tokensLeft, "userid": this.adgenieuserid })
        // this.warnMessage = "You need to purchase more tokens to download";
        var imageid = imageUrl.split("/").pop().split(".")[0]
        var ext = imageUrl.split("/").pop().split(".")[1]

        var extcode = 3
        if (ext == "jpg") {
          extcode = 1
        }
        if (ext == "png") {
          extcode = 2
        }
        if (ext == "mp4") {
          extcode = 3
          // imageid = this.videomap[imageUrl] //Get original video for watermarked
        }
        amplitude.track('DownloadImage', { "imageUrl": imageUrl, "productid": this.productId, "type": ext });



        this.download_utm_content = this.adgenieuserid + "_" + imageid + "_" + extcode


        if (extcode == 3) { //video 
          this.$refs.buyvideo.toggle(eventd);
        }
        else {
          this.$refs.buydownload.toggle(eventd);
        }


        return;

      }

      try {
        const response = await fetch(imageUrl, {
          headers: {
            'Cache-Control': 'no-cache'
          }
        });
        console.log(response)
        amplitude.track('DownloadImageSuccessOld', { "imageUrl": imageUrl, "productid": this.productId });
      } catch (error) {

        console.error('There was an error downloading the image', error)
        window.open(imageUrl, '_blank');
        amplitude.track('DownloadCORSError', { "imageUrl": imageUrl, "productid": this.productId });
      }

    },


    async uploadFile(file) {
      const apiGatewayUrl = "https://zxdfsnfpi7zpbigdob53jlsrta0yujhn.lambda-url.us-west-1.on.aws/";

      // Step 1: Get the presigned URL from API Gateway
      const fileName = file.name;
      const presignedUrlResponse = await fetch(`${apiGatewayUrl}?file_name=${encodeURIComponent(fileName)}`);

      if (!presignedUrlResponse.ok) {
        throw new Error("Failed to get presigned URL from API Gateway");
      }

      const presignedUrlData = await presignedUrlResponse.json();

      const uploadUrl = presignedUrlData.upload_url;
      console.log("Upload URL",uploadUrl)
      const finalUrl = presignedUrlData.final_url;
      amplitude.track('DocumentUploaded', { "productid": this.productId, "input": this.pText, "file": file.name, "url": finalUrl });
      console.log("upload url is ", uploadUrl)

      console.log("final url is ", finalUrl)

      // Step 2: Upload the file to the presigned URL
      const uploadResponse = await fetch(uploadUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": "*/*"  // Make sure this is set correctly
        }
        
      });
      console.log("upload response is ", uploadResponse)

      if (uploadResponse.ok) {
        console.log("File uploaded successfully!", finalUrl);
        this.getDocImages(finalUrl)
      return finalUrl;
      } else {
        const errorText = await uploadResponse.text();
        console.log("Error response:", errorText);
        return null;
      }
    },




    refreshthemeimages(theme) {
      this.refreshspinner = true;
      this.themeimages[theme] = [];
      amplitude.track('RefreshThemeImages', { "theme": theme, "productid": this.productId });
      this.lastjobstartseconds = new Date().getTime() / 1000
      const fetch = require('node-fetch');
      const querystring = require('querystring-es3');

      var currentproduct = ""

      if (this.productId != null && this.productId != undefined && this.productId != "") {
        currentproduct = this.productId;
      }
      else {
        currentproduct = this.pText;
      }


      let queryParams = {
        p: currentproduct,
        // theme: theme,
        jobid: this.jobid
      };

      // Convert your query parameters into a string
      let queryString = querystring.stringify(queryParams);



      // Perform the GET request
      // fetch(`https://6lpm5pwireoybtd4s5bo2jc3ca0yksge.lambda-url.us-west-1.on.aws/getAd?${queryString}`) //demo
      fetch(`https://akho5uh6vpcl3j4ahjbcb5fsla0jrjem.lambda-url.us-west-1.on.aws/getAd?${queryString}`)
        .then(response => response.json())
        .then(data => this.processOutput(data)).then(() => this.refreshspinner = false)
        .catch(error => { console.log("Error in refresh",error) ; this.loading = false; this.warnMessage = "Unable to get any more images."; });
    },

    processOutput(data) {

      // this.statusimages = [];

      this.loading = false;
      this.refreshspinner = false;
      for (const eachline of data) {


        if (eachline.header == null) {
          if (!(eachline.theme in this.themeimages)) {
            this.themeimages[eachline.theme] = [];
            this.themes.push(eachline.theme);

          }

          if (!this.statusimages.includes(eachline.image)) {
            this.statusimages.push(eachline.image);
            amplitude.track('ImageGenerated', { "imageURL": eachline.image, "productid": this.productId, 'timetaken': new Date().getTime() / 1000 - this.lastjobstartseconds })
            if (this.showimages) {
              this.scrollToBottom();
            }
          }

          this.themeimages[eachline.theme].push({ "itemImageSrc": eachline.image, "thumbnailImageSrc": eachline.image, "alt": eachline.caption.trim() });


        }
        else {
          if (eachline.productId != null || eachline.productId != undefined || eachline.productId != "") {

            if (this.productId != eachline.productId && this.productId != null && this.productId != undefined && this.productId != "" && eachline.productId != null && eachline.productId != undefined && eachline.productId != "" && !(eachline.productId.length == 10 && this.productId.includes("amazon"))) {

              this.statusimages = [];
              this.videoimages = []
              // this.videoimages = ["https://cdn.shotstack.io/au/stage/tyg8aqmav5/56484bde-0de1-4ed0-a6ef-a656c3d046ef.mp4", "https://cdn.shotstack.io/au/stage/tyg8aqmav5/e483c1cc-73b0-43a8-be14-a9f18cf195b4.mp4"]
              console.log("NOT REFRESHING PRODUCT ID")
            }

            if (this.productId != eachline.productId) {
              this.statusimages = [];
              this.videoimages = []
            this.productId = eachline.productId;
            console.log("Fetching product videos for", eachline.productId)
            this.fetchProductVideos(eachline.productId);
            }
          }
        }


      }

      // else {
      //   this.themes = eachline.themes.split(",")
      //   for (const eachtheme of this.themes) {
      //     this.themeimages[eachtheme] = [];
      //   }


      //   console.log(this.themes);
      // }
      // }
      this.jobcounter += 1;
      this.$emit('incrementjob', this.jobcounter)

    },

    getUserCountry() {

      fetch('https://mz6cnkmpumbpan5mnar4l4bv2a0geyyk.lambda-url.us-west-1.on.aws/')
        .then(response => response.json())
        .then(data => {

          this.usercountry = data.countryCode;
          amplitude.track('User Country Identified', { "country": this.usercountry });
          if (document.referrer.includes("acuit") || window.location.href.includes("acuit")) {
            this.usercountry = "IN"
          }
          if (this.usercountry == "IN") {
            this.downloadprice = "₹49";
            this.videodownloadprice = "₹99";
          }
          else {
            this.downloadprice = "$1.99";
            this.videodownloadprice = "$2.99";
          }
        }
        );

    },

    showNAMsg(events) {
      amplitude.track('showNAMsg', {});
      event('showNAMsg', {})
      this.$refs.op.toggle(events);
    },

    showDownloadPanel(image, events) {


      amplitude.track('DownloadPanelOpened', { "image": this.image });
      event('DownloadPanelOpened', { "selectedthemes": this.image })
      this.$refs.opd.toggle(events);
    },

    fetchStatus() {
      if (this.jobid == null || this.jobid == undefined || this.jobid == "") {
        return
      }
      fetch('https://tv2fr6ulib.execute-api.us-west-1.amazonaws.com/prod/jobstatus?jobid=' + this.jobid, { method: "GET" })
        .then(response => response.json())
        .then(data => this.processData(data));
    },

    fetchProductVideos(productId) {
      console.log("Fetching product videos inside for", productId)

      var payload = { "productId": productId }

      fetch("https://iqd7sqvpmlgtstetisv57hyp4q0aorxt.lambda-url.us-west-1.on.aws/", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      }).then(response => response.json())
        .then(data => {


          data["videos"].forEach(video => {
            this.showsidebar = true;
            if (!this.videoimages.includes(video)) {
              if (this.videoimages.length == 0) {
                this.selectedVideoId = video.split("/").pop().split(".")[0]

                //set showsidebar to false after 2 seconds
                setTimeout(() => {
                  this.showsidebar = false;
                }
                  , 2000)
              }
              this.showDocUploader = false;
              if (video.includes("mp4")) {
              this.videoimages.push(video)
              }
              if (video.includes("pptx")) {
                this.pptximages.push(video)
              }

              this.imagebtnstate = false;
              this.videobtnstate = true;
              this.mediaformatpreference = "Video Ads"
              this.showimages = false;
              this.showvideos = true;
            }
          })
        }

        )
    },

    insertVideoJob() {

      if (this.productId == null || this.productId == undefined || this.productId == "") {
        this.productId = this.pText;
      }
      var payload = { "jobid": this.jobid, "productId": this.productId, "userid": this.adgenieuserid }
      fetch('https://fqsng7s4qmv6z4dbd5hy2t2vwu0fzqfh.lambda-url.us-west-1.on.aws/', {
        method: "POST", headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(data => console.log(data));
    },

    getVideo(templateid, stock = false) {
      var url = "https://utu7suvdrtyfsiixzpa6juzbtq0umaks.lambda-url.us-west-1.on.aws/?"
      if (stock) {
        url = url + "STVideo=true&"
      }
      var env = "preview"
      if (this.usercountry == "US") {

        env = "prod"
      }
      this.videosToGenerate += 1;


      const FETCH_TIMEOUT = 175000; // Set timeout in milliseconds
      let didTimeOut = false;

      new Promise((resolve, reject) => { // Use arrow function here
        const timeout = setTimeout(() => { // Also arrow function here
          didTimeOut = true;
          reject(new Error('Request timed out'));
        }, FETCH_TIMEOUT);

        fetch(url + 'product=' + this.productId + "&templateid=" + templateid + "&jobid=" + this.jobid + "&userid=" + this.adgenieuserid + "&env=" + env, { method: "GET" })
          .then(response => response.json())
          .then(data => {
            clearTimeout(timeout);
            clearInterval(this.progresstimer);
            if (!didTimeOut) {
              this.processVideo(data);
              resolve(data);
            }
          })
          .catch(err => {

            amplitude.track('VideoFetchError', { "error": err, "templateid": templateid });
            clearInterval(this.progresstimer);
            this.videosToGenerate -= 1;

            clearTimeout(timeout);
            if (!didTimeOut) reject(err);
          });
      })
        .catch(err => {
          clearInterval(this.progresstimer);

          this.videosToGenerate -= 1;

          amplitude.track('VideoFetchError', { "error": err, "templateid": templateid });
        });
    },

    processVideo(data) {

      if (data.video !== undefined &&  data.video.includes("SPTMP-36S-DOC-1")) {
        this.statusmsg = "Creating Video #2 - Detailed Video...";
      }
      console.log("videodata is",data)
      if (data.pptxurl !== undefined && data.pptxurl.length > 0) {
        this.showsidebar = true;
        if (!this.pptximages.includes(data.pptxurl)) {
          if (this.pptximages.length == 0 && this.videoimages.length == 0) {

            this.selectedPPTXId = data.pptxurl.split("/").pop().split(".")[0]
            console.log("selected pptx id", this.selectedPPTXId)
            setTimeout(() => {
              this.showsidebar = false;
            }
              , 2000)

          }
          this.showDocUploader = false;
          this.pptximages.push(data.pptxurl)
          amplitude.track('PPTX Generated', {"pptxurl":data.pptxurl}) }
          if (data.previewimage !== undefined && data.previewimage.length > 0) {
            console.log("preview image is",data.previewimage)
          this.pptxpreview.push(data.previewimage)
          }

        }
        this.overlayExpanded.push(false)
        amplitude.track('PPTXGenerated', { "fileURL": data.pptxurl, "productid": this.productId });
        event('VideoGenerated', { "videoURL": data.pptxurl, "productid": this.productId })
        // this.videomap[data.video] = data.origvideoid

        if (this.showvideos) {
          this.scrollToBottom();
          console.log("videoimages length",this.videoimages.length,this.pptximages.length,this.videosToGenerate)
          if ((this.videoimages.length+this.pptximages.length) >= this.videosToGenerate) {
            this.videoloading = false;
            this.processComplete = true;
            this.refreshspinner = false;
            this.loading = false;
          }
        }
      



      if (data.video !== undefined && data.video.length > 0) {
        this.showsidebar = true;
        if (!this.videoimages.includes(data.video)) {
          if (this.videoimages.length == 0) {

            this.selectedVideoId = data.video.split("/").pop().split(".")[0]
            setTimeout(() => {
              this.showsidebar = false;
            }
              , 2000)

          }
          this.showDocUploader = false;
          this.videoimages.push(data.video)

        }
        this.overlayExpanded.push(false)
        amplitude.track('VideoGenerated', { "video": data.video, "productid": this.productId });
        event('VideoGenerated', { "videoURL": data.video, "productid": this.productId })
        this.videomap[data.video] = data.origvideoid

        if (this.showvideos) {
          this.scrollToBottom();
          console.log("videoimages length",this.videoimages.length,this.pptximages.length,this.videosToGenerate)
          if ((this.videoimages.length+this.pptximages.length) >= this.videosToGenerate) {
            this.videoloading = false;
            this.processComplete = true;
            this.refreshspinner = false;
            this.loading = false;
          }
        }
      }
    },

    getPoster(eventname, templateid) {
      // var url="https://utu7suvdrtyfsiixzpa6juzbtq0umaks.lambda-url.us-west-1.on.aws/?"
      var url = "https://3dnfpbtl5k2fry7mnnajloq2uq0qjuhy.lambda-url.us-west-1.on.aws/?" //Demo




      fetch(url + 'eventname=' + eventname + "&templateid=" + templateid, { method: "GET" })
        .then(response => response.json())
        .then(data => {
          this.processPoster(data);


        })
        .catch(err => {
          console.error('Fetch error:', err);

        });

    },

    processPoster(data) {
      if (data.url !== undefined && data.url.length > 0) {
        this.posterimages.push(data.url)
        if (this.posterimages.length > 1) {
          this.refreshspinner = false;
          this.loading = false;
        }
      }
    },

    async scheduleJob() {

      // App.config.globalProperties.$toast.position="top-right";

      //const response = await fetch('https://tv2fr6ulib.execute-api.us-west-1.amazonaws.com/prod/scheduleAdJob?product=' + this.productId, { method: "GET" }) //Demo
      const response = await fetch('https://tv2fr6ulib.execute-api.us-west-1.amazonaws.com/prod/ScheduleAdJobRegQ?product=' + this.productId, { method: "GET" })
      const data = await response.json();
      const isdata = (typeof data.jobId === 'undefined') || (data.jobId == null);
      if (!isdata) {
        this.jobid = data.jobId;
        amplitude.track('JobScheduled', { "productid": this.productId, "jobid": data.jobId, "tokensLeft": this.tokensLeft, "userid": this.adgenieuserid, "jobType": "images" });
        return data.jobId;
      }
      else {

        return null;
      }

    },
    playIntroVideo() {
      const introvidplayer = this.$refs.introvideo;
      introvidplayer.muted = true;
      introvidplayer.play();
    },
    pauseIntroVideo() {
      const introvidplayer = this.$refs.introvideo;
      introvidplayer.pause();
    },

    completeIntroVideo() {
      const introvidplayer = this.$refs.introvideo;
      amplitude.track('IntroVideoCompleted', { "userid": this.adgenieuserid ,"video":introvidplayer.src});

      introvidplayer.pause();
    }






  },
  mounted() {
    document.title = this.msg;
    this.getUserCountry()
    if (this.jobid != null && this.jobid != undefined && this.jobid != "") {
      this.fetchStatus();
      this.isMoved = true;
      this.processComplete = true;


    }

    var placeholders = []

    if (document.referrer.includes("fictic") || window.location.href.includes("fictic")) {
      // const referrerQueryParamStr = new URL(document.referrer).search



      amplitude.track('FicticUser', { "referrer": document.referrer, "currenturl": window.location.href });

      this.title = 'Videos from Text or Document',
        this.subtitle = 'visuals, videos and slideshows',

        placeholders = [
          "create video slideshow",
          "video from powerpoint",
          "create video presentation from pdf",
          "video for my website",
          "create video from document"

        ];



    }
    else {

      placeholders = [
        "Ad for an accounting service",
        "Make a video ad for fire alarm system",
        "Video ad for a car detailing service",
        "Ad for a mountain bike",
        "Travel pillow video ad"
      ];


    }

    let index = 0;
    const inputElement = document.querySelector('.oversized-textbox');


    setInterval(() => {
      inputElement.setAttribute('placeholder', placeholders[index]);
      index = (index + 1) % placeholders.length;
    }, 2500);


    this.$nextTick(() => {
      const videoItems = this.$refs.videoitem;
      if (videoItems !== undefined) {
        videoItems.forEach(item => {
          item.setAttribute('tabindex', '0'); // Make div focusable
          item.addEventListener('keydown', this.handleKeydown);
        });
      }

    });


    // this.$primevue.changeTheme('md-dark-indigo', 'md-light-indigo', 'theme-link', () => {});
  },
  beforeUnmount() {
    const videoItems = this.$refs.videoitem;
    if (videoItems !== undefined) {
      videoItems.forEach(item => {
        item.removeEventListener('keydown', this.handleKeydown);
      });
    }
  }


}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


:root {
  --focus-ring: #ff000d;

}

.homepage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 50px;


}

.tag-header {
  /* font-size: 0.7em; */
  color: rgb(241, 158, 56);
  /* margin-top: 0px;
  margin-left: 0px; */
  text-align: left;
  position: relative;
}

.tag-text {
  font-size: 1em;
  color: rgb(239, 240, 243);
  width: 80%;
  justify-content: center;
  ;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
  align-items: center;
  flex-direction: column;
}


.title {
  font-size: 3em;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #FFF;
  margin: 20px 0 15px 0;
}

.subtitle {
  font-size: 22px;
  font-weight: 400;
  color: #FFF;
}

.subtitle span {
  color: #E76408;
}

.filtertoggle {
  margin-top: 50px;
  justify-content: space-around;

}




.dialog-header {
  font-size: 1.1em;
  font-weight: 00;
  color: rgb(239, 240, 243);
  width: 100%;
  justify-content: center;
  text-align: center;
  font-family: "Raleway", sans-serif;
}

.search_group {
  margin-top: 40px;
  background: #1E1E1E;
  border-radius: 30px;
  text-align: center;
  padding: 50px;
  width: 100%;
  /* margin-bottom: 100px; */
}



body,
html {

  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;

  font-family: Arial, sans-serif;

  --focus-ring-color: #007BFF;
  --focus-ring-alpha: 0.2;
}

.downloadimg {
  width: 50px;
  height: 50px;
  margin-left: 20px;
  background: transparent;
}

.downloadimg-disabled {
  width: 50px;
  height: 50px;
  margin-left: 20px;
  background: transparent;
  filter: grayscale(100%);


}

.p-dialog-header span {
  font-size: 1.1em;
  font-weight: 800;
  color: rgb(239, 240, 243);
  width: 100%;
  justify-content: left;
  text-align: left;
  font-family: "Raleway", sans-serif;

}

.download-button {
  width: 50px;
  height: 50px;
  margin-left: 0rem;
  background: transparent;
  border: none;
}



.emailform {
  width: 45rem;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  align-content: center;

}



.features-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  font-size: 1.1em;
  font-weight: 400;
  color: rgb(239, 240, 243);
  text-align: left;
}

.featurekey {
  font-size: 0.8em;
  font-weight: 400;
  font-style: italic;
  color: rgb(239, 240, 243);
  width: 40%;
  justify-content: left;
  text-align: left;
  font-family: "Raleway", sans-serif;
}

.features-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: rgb(239, 240, 243);
  text-align: left;
  margin-left: 0px;
}

.featuredesc {
  font-size: 0.8em;
  font-weight: 200;
  color: rgb(239, 240, 243);
  width: 80%;
  justify-content: left;
  font-family: rale;
  text-align: left;
  font-family: "Raleway", sans-serif;
}

.sharemessage {
  position: absolute;
  bottom: 10%;
  left: 0%;
  z-index: 4;
  opacity: 1;

}


.play-button {
  transform: translate(-50%, -50%);
  cursor: pointer;
  margin-top: 10px;
  width: 75px;
  /* Adjust size as needed */
  height: 75px;
  /* Adjust size as needed */
  z-index: 4;
  border-radius: 50px;
}

.edit-video-btn {
  background: #E76408;
  color: #FFF;
  padding: 10px 25px;
  border-radius: 30px;
  border: 0;
  font-weight: 600;
  font-size: 20px;
  justify-content: center;
  cursor: pointer;


}

.edit-overlay-btn {
  background: #E76408;
  color: #FFF;
  padding: 10px 25px;
  border-radius: 30px;
  border: 0;
  font-weight: 600;
  font-size: 20px;
  justify-content: center;
  cursor: pointer;
  position:absolute;
  z-index: 5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);


}

.edit-overlay-btn:hover {
  transform: scale(1.1);
}

.edit-video-btn:hover {
  transform: scale(1.1);
}

.download-video {
  margin-top: 20px;
  cursor: pointer;
  width: 75px;
  /* Adjust size as needed */
  height: 75px;
  /* Adjust size as needed */
  z-index: 4;
  border-radius: 50px;

}

.download-video:hover {
  transform: scale(1.1);
}

.share-video:hover {
  transform: scale(1.1);
}




.videothumbnail {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

}

.previewvideo {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #E76408;
  cursor: pointer;
  max-height: 15vh;
  max-width: 9vw;

}

.previewpptx {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #E76408;
  cursor: pointer;
  max-height: 15vh;
  max-width: 9vw;
  aspect-ratio: 16/9;

}

.previewpptx:hover {
  border: 2px solid #E76408;
  transform: scale(1.05);

}

.previewvideo:hover {
  border: 2px solid #E76408;
  transform: scale(1.05);

}

.share-video {
  margin-top: 20px;
  cursor: pointer;
  width: 75px;
  /* Adjust size as needed */
  height: 75px;
  /* Adjust size as needed */
  z-index: 4;
  border-radius: 50px;
}



.play-button-landscape {
  position: absolute;
  left: 20%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 75px;
  /* Adjust size as needed */
  height: 75px;
  /* Adjust size as needed */
  z-index: 4;
  border-radius: 50px;
}

.download-video-landscape {
  position: absolute;
  left: 40%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 75px;
  /* Adjust size as needed */
  height: 75px;
  /* Adjust size as needed */
  z-index: 4;
  border-radius: 50px;

}

.share-video-landscape {
  position: absolute;
  background: #E76408;

  left: 60%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 75px;
  /* Adjust size as needed */
  height: 75px;
  /* Adjust size as needed */
  z-index: 4;
  border-radius: 50px;

}

.presentationbar{
  width:100%;
  height: 50px;
}


.videoactions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 70px;
}

.nextvideooverlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 0%;
  height: 35%;
  /* Adjust size as needed */
  /* Adjust size as needed */
  z-index: 5;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition: width 1s ease;
  display: flex;
}

.nextvideooverlay.expanded {
  width: 100%;
  /* Full width on hover */
  left: 50%;
}

.nextvideoimage {
  height: 80%;
}

.nextvideobutton {
  border-radius: 20px;
}

.email-form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  font-size: 1em;
  font-weight: 400;
  color: rgb(239, 240, 243);
  text-align: left;
}

.status-text {
  color: whitesmoke;
  font-size: 1.3em;

}

.email-input {
  width: 70%;
  height: 2.3rem;
  font-size: 1em;
  font-weight: 400;
  color: rgb(239, 240, 243);
  text-align: left;
}

.download-op {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: 80%;
}

.docuploaderdiv {
  margin-top: 10px;
}

.docuploaderdiv.drag-over {
  background-color: #7c929c;
  /* Change to your desired color */
}

.buybutton {
  border-radius: 20px;
  width: 95%;
  z-index: 10;
}

.gallery-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 100px;
  margin-left: 20px;
  margin-right: 20px;

}

.checlkbox-group {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 10px;
  width: 100%;
}

.adcheckbox {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 40px;
  align-items: center;
  color: #007BFF
}

.themecheckbox {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 40px;
  align-items: center;
}

.field-checkbox {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 10px;
  width: 100%;
}

.imagepanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
  /* black shadow with 50% transparency */

}


.oversized-textbox {
  width: 100%;
  height: 80px;
  border-radius: 45px;
  font-size: 1.5rem;
  padding: 10px 200px 10px 25px;
  background: #121111;
  border: 1px solid #616161;
  transition: placeholder 1s ease-in-out;
  margin-left: -120px;


}

.instructionvideo {
  margin-top: 50px;
  width: 100%;
  display: inline-block;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: #18181B 1px solid;
  /* box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.5); */

}

.instructionvideo:hover {
  box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.5);
}


.oversized-textbox::placeholder {
  display: inline-block;
  animation: fadeInOut 4s infinite;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

/* @keyframes roll-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-100%);
    opacity: 0;
  }
  51% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */

.ovtex {
  /* Oversized width */

  /* Stylish border */

  /* Spacing between textbox and button */
}

.go-button-down,
.go-button-right {
  background: #E76408;
  position: fixed;
  color: #FFF;
  padding: 10px 25px;
  border-radius: 30px;
  border: 0;
  font-weight: 600;
  font-size: 20px;
  display: inline-block;
  margin-left: -7.5em !important;
  margin-top: 0.8em;
  margin-bottom: 15px;
}

.generatebutton {
  background: #E76408;
  color: #FFF;
  padding: 10px 25px;
  border-radius: 30px;
  border: 0;
  font-weight: 600;
  font-size: 20px;
  margin-top: 0.8em;
  justify-content: center;

}

.emptyMessage {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.rightvideobutton {
  display: block;
  height: 30px;
  width: 30px;
  position: fixed;
  right: 20px;
  top: 48%;
}

.vtext {
  display: block;
  height: 30px;
  width: 30px;
  position: fixed;
  right: 0;
  top: 50%;
  transform: rotate(90deg);
  /* Rotate the text */
  transform-origin: right top;
  /* Adjust the origin to keep the text in place */
  color: #FFF;
}

.rightvideopane {
  display: block;
  height: 100vh;
  width: 30px;
  position: fixed;
  right: 0;
  top: 0%;
  background-color: #18181B;
}

.editbutton {

  /* border-radius:30px; */
  border: 0;


  display: inline-block;
  margin-right: -40px;
  margin-left: -60px;
  margin-bottom: 15px;
  margin-top: 10px;
  cursor: pointer;




}

.editbutton img {
  content: url('@/assets/editbutton.png');
}

.editbutton:hover img {
  content: url('@/assets/editbutton_hover.png');

}

.docicon {
  /* border-radius:30px; */
  border: 0;


  display: inline-block;
  margin-right: -40px;
  margin-left: -60px;
  margin-bottom: 15px;
  margin-top: 10px;
  cursor: pointer;
}

.docicon img {
  content: url('@/assets/docicon.png');
}

.docicon:hover img {
  content: url('@/assets/docicon_hover.png');

}


.go-button-down {

  margin-top: 20px;
}

.statuspanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 90%;
  max-width: 640px;
  font-size: x-large;

}

.go-button-right {
  margin-left: 10px;
  position: absolute;
}

.go-button-down:hover,
.go-button-right:hover {
  background-color: #93C5FD;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 60rem;
  font-size: medium;
  margin-bottom: 2.5rem;
  margin-top: 1.3rem;
}

.logo {
  width: 100px;
  height: 100px;
  top: 20px;
  left: 20px;
  align-items: left;
  position: relative;
}



.themepanel {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 150px;
  margin-right: 150px;
  width: 100%;
}

.captionlabel {
  background-color: rgba(255, 255, 255, 0.1);
  /* white with 50% transparency */
  color: whitesmoke;
  font-size: 1rem;
  /* big font */
  padding: 10px;
  width: 100%;
  text-align: center;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 80%;
}

.top {
  position: relative;
  margin-top: 4rem;
  margin-bottom: 30px;
  width: 100%;
}

.center,
.top {
  /* Other styles */
  transition: all 0.8s ease;
  /* Change duration as needed */
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 10px;
  position: relative;
  margin-top: 50px;
  width: 100%;


}

.staticsample {
  position: absolute;
  background: white;
}

.staticsample:hover {
  opacity: 0;
}


.grid .image-container:nth-child(-n+3) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: bottom;
  justify-content: flex-end;
}

/* .videocontainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  gap: 50px;
  position: relative;
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  align-items: bottom;
} */

.videocontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

}

.videothumbnailcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 2px;
  justify-content: flex-start;
  align-items: center;
}




.video-wrapper video {
  heigth: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  border: 2px solid #020617;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
  align-self: center
}

.videocontainer .videowatermark {
  position: absolute;
  width: 100%;
  height: 90%;
  pointer-events: none;
  /* Ensure the watermark doesn't interfere with video controls */
}

.maingrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 50px;
  position: relative;
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  align-items: bottom;

}

.postergrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  gap: 50px;
  position: relative;
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  align-items: bottom;


}



.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(207, 22, 22, 0.03);
  transition: transform 0.3s ease-in-out;

}




.imageoptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;

}


.image-container {
  position: relative;
}

.image-container img {
  width: 100%;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.themebutton {
  margin-left: 1vw;
  margin-right: 1vw;
}

.noamazon {
  color: rgb(108, 126, 175);
  text-decoration: underline;
  cursor: pointer;
}

.refreshstatus {
  width: 100%;
  height: 100%;
}



.image-container:hover img:hover {
  transform: scale(1.1);
}


.input-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

/* The icon style */
.icon {
  padding: 10px;
  background: dodgerblue;
  color: white;
  min-width: 50px;
  text-align: center;
}

/* The input field - we use box-sizing to add the padding inside the input field */
.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
}

.morebutton {
  margin-top: 3rem;
  width: 5rem;
  border-radius: 10px;
  color: #FFF;
  /* background:#E76408; */
  font-weight: 600;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}


.rem {
  position: relative;

  /* other grid-item styles */
}

.grid-item {
  position: relative;
}

.grid-item img {
  width: 100%;
  height: auto;
  -webkit-touch-callout: none;
}

/* .centered-button {
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
} */

.p-dialog .previewdialog {
  width: 40vw;
  max-width: 350px;
  min-height: 500px;
  resize: horizontal;
  overflow: auto;
}




.psupport {

  margin: 2px;
  width: auto;
  height: 50px;
  align-self: center;
}

.imageactions {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 13rem;
  height: 5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  justify-content: space-evenly;
  border-radius: 20px;
  border-width: 3px;
  border-color: #020617;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 1rem;

  /* other button styles */
}

.watermark {
  position: absolute;
  top: 40%;
  left: 0;
  transform: rotate(-45deg);
  transition-duration: 0ms;
  transition: none;
  opacity: 0.5;
  z-index: 7;
  /* Adjust these values as needed */
  /* other styles */
}

.videowatermark {
  position: absolute;
  width: 100%;
  /*  max-width: 550px;*/
  /* transform: rotate(-60deg); */
  opacity: 0.7;
  z-index: 3;
  pointer-events: none;

}



.grid-item:hover .imageactions {
  z-index: 10;
  opacity: 1;
}

.grid-item:hover .centered-button {
  opacity: 1;
  cursor: pointer;
  z-index: 11;
}

/* .imageactions{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
} */




@media(max-width:960px) {

  .maingrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .title {
    font-size: 30px;
  }

  .subtitle {
    font-size: 16px;
  }

  .docuploaderdiv {
    width: 85%;
    margin-top: 80px;
  }

  .search_group {
    padding: 30px 15px !important;
  }

  .oversized-textbox {
    /* display:block; */
    width: 100%;
    height: 60px;
    font-size: 16px;
    /* margin-left: -50px; */
    padding: 7px 15px !important;
  }

  .go-button-right {
    display: block;
    position: absolute;
    margin: 15px auto 0 auto !important;
    ;
    width: 100%;
  }




  .top {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  /* .play-button {
    top: 30%;
    left: 50%;
  }

  .download-video {
    top: 50%;
    left: 50%;

  }

  .share-video {
    top: 70%;
    left: 50%;

  }

  .play-button-portrait {
    top: 30%;
    left: 50%;
  }

  .download-video-portrait {
    top: 50%;
    left: 50%;

  }

  .share-video-portrait {
    top: 70%;
    left: 50%;

  } */

}

@media(max-width:768px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
  }

  .docuploaderdiv {
    width: 85%;
    margin-top: 40px;
    min-height: 140px;
  }

  .p-panel {
    width: 85%;
    margin-top: 100px;
  }

  .maingrid {
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
  }




  .play-button {
    display: none;
  }

  .play-button-portrait {
    display: none;
  }

  /* 
  .download-video {
    top: 75%;
    left: 20%;
  }

  .download-video-portrait {
    top: 75%;
    left: 25%;
  }

  .share-video {
    top: 75%;
    left: 80%;
  }


  .share-video-portrait {
    top: 75%;
    left: 75%;
  } */

  p-sidebar-right .p-sidebar {
    width: 100vw !important;
    height: 100%;
  }

  .previewvideo {
    max-height: 30vh;
    max-width: 90vw;
  }

}

.badge {
    display: inline-block;
    background-color: #E76408;
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 14px; /* Adjusted to fit the text better */
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>