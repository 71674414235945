<template>
    <div class="footer-div" id="footer">
          <div class="frame-group">
            <div  v-on:click="onclick('Privacy')" class="h2-heading"><a href="https://www.fictic.ai/privacy.html">Privacy Policy</a></div>
            <div  v-on:click="onclick('TOS')" class="h2-heading"><a href="https://www.fictic.ai/tos.html">Terms of Use</a></div>
<!--            <div  v-on:click="onclick('Share')" > <a href="#bottom-sheet"> <img class="footer-image" src="../assets/shareicon.png"> </a></div>
            <div  v-on:click="onclick('Feedback')" > <a href="#feedback-sheet"> <img class="footer-image" src="../assets/infoicon.png"> </a></div>
           -->
            <!-- <ShareComp></ShareComp>
            <FeedbackComp></FeedbackComp> -->
          </div>
        </div>
  </template>

  <script>
  // import ShareComp from './ShareSheet.vue'
  // import FeedbackComp from './Feedback.vue'
  import * as amplitude from '@amplitude/analytics-browser';
  export default {
  name: "FooterComp",
  methods:{

      onclick(link) {
          console.log("CLICKED");
          amplitude.track('Footer link clicked',{FooterLink:link});

      }
  }
  // components: {
  //     ShareComp,
  //     FeedbackComp
  // }
  }
  </script>

  <style scoped >

.footer-div {
  background-color: var(--color-white);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  margin-top:150px;
}
.h2-heading a{
  color:#FFF;
  text-decoration:none;
}

.footer-div .frame-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
}

.footer-image {
  height: 2rem;
  width: 2rem;
}





</style>
