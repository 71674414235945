<template>
    <div class="container">
        <button @click="this.getFirstImage()"> Click </button>
        <img v-if="this.adslist.length > 0" :src="this.adimg"/>
        {{ this.adslist[0] }}
         
    
</div>


</template>

<script>


export default {
    name: 'dynamicAds',
    components: {  },
    emits:[],
    props:["productId"],
    data() {
        return {
            adslist:[],
            adimg:""
        
        }
    },
    methods:
    {
    fetchAds()
        {
            console.log("fetchAds",this.productId);
            fetch(`https://tv2fr6ulib.execute-api.us-west-1.amazonaws.com/prod/ads/imageinfo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({productId:this.productId})
            })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                data.forEach(eachad => {
                    if (eachad.type=="generated" && eachad.bgURL == null) {
                        this.adslist.push(eachad);
                        this.adimg='https://adgenimages.s3.us-west-1.amazonaws.com/genimages/'+ this.adslist[0].uuid+'.png'
                    } })
                });
            

            
        },
        getFirstImage()
        {
            console.log("getFirstImage");
            var ad= this.adslist[0];
            console.log(ad);
            const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "Bearer bb_pr_1f67a2339826059f558d13949272b6");

const raw = JSON.stringify({
  "template": "4KnlWBbKvy9lDOQGgm",
  "modifications": [
    {
      "name": "image_container",
      "image_url": "https://cdn.bannerbear.com/sample_images/welcome_bear_photo.jpg"
    },
    {
      "name": "title",
      "text": "You can change this text",
      "color": null,
      "background": null
    },
    {
      "name": "contact",
      "text": "You can change this text",
      "color": null,
      "background": null
    }
  ],
  "webhook_url": null,
  "transparent": false,
  "metadata": null
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch("https://sync.api.bannerbear.com/v2/images", requestOptions)
  .then((response) => response.json())
  .then((result) => this.adimg=result.image_url)
  .catch((error) => console.error(error));




        }
    },

  

        

        mounted() { console.log("created in dynamicAds",this.productId);
        this.fetchAds();

        },
        created() {

        }
    }


</script>
<style scoped>

.adimg {
    max-width: 100%;
    height: auto;
    -webkit-touch-callout: none;
    justify-content: center;
}
.adfooter{
    margin-top:10px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.logo {
    width: 50px;
    height: auto;
}
 
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: top;
  height: 100vh; /* Adjust as needed */
}

.poweredby {
    margin-top: 14px;
    color:beige;
}
@media screen and (max-width: 1000px) {
   
    
}

@media screen and (max-width: 768px) {
   
 
}


</style>