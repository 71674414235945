<template>
    <div class="docinputgroup">
        <p class="inputlabel" :style="{color: this.objective == '' ?'red' : 'white'}" > Objective </p>
        <InputText placeholder="What is the Objective of the video" v-model="objective" @change="changeObjective()" />
        <p class="inputlabel" :style="{color: this.audience == '' ?'red' : 'white'}"> Audience </p>
        <InputText placeholder="Who is the Audience for this video" v-model="audience" />
        <p class="inputlabel" :style="{color: this.aspect == null ?'red' : 'white'}"> Aspect Ratio </p>
        <div class="AspectGroup">
            <div>
                <RadioButton inputId="rb1" name="aspect" value="portrait" v-model="aspect" />
                <label class="label" for="rb1">Portrait</label>
            </div>
            <div>
                <RadioButton inputId="rb2" name="aspect" value="landscape" v-model="aspect" />
                <label class="label" for="rb1">Landscape</label>
            </div>
        </div>
        <p class="inputlabel" :style="{color: this.contentmode == null ?'red' : 'white'}"> Text Content </p>
        <div class="AspectGroup">
            <div>
                <RadioButton inputId="rb3" name="contentmode" value="preserve" v-model="contentmode" />
                <label class="label" for="rb3">Preserve Original</label>
            </div>
            <div>
                <RadioButton inputId="rb4" name="contentmode" value="rewrite" v-model="contentmode" />
                <label class="label" for="rb4">Rewrite using AI</label>
            </div>
        </div>
        <Button class="submitbutton" label="Submit" @click="onSubmit()"
            :disabled="objective == '' || audience == '' || aspect == null || contentmode ==  null" />
    </div>


</template>

<script>
import * as amplitude from '@amplitude/analytics-browser';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import InputText from 'primevue/inputtext';
export default {
    name: "DocumentInput",
    props: {
        inputstring: String
    },
    components: { InputText, Button, RadioButton },
    emits: ["onDocumentInput"],

    data() {
        return {
            objective: "",
            audience: "",
            aspect: null,
            contentmode: null
        };
    },
    methods: {

        onSubmit() {
            console.log("CLICKED");
            amplitude.track('Document Input submitted', { "Objective": this.objective, "Audience": this.audience, "Aspect": this.aspect, "ContentMode": this.contentmode });
            this.$emit('onDocumentInput', this.objective, this.audience, this.aspect, this.contentmode);


        },
        changeObjective() {
            console.log("Objective changed", this.objective);
            amplitude.track('Objective changed', { "New Objective": this.objective, "Old Objective": this.inputstring });
        }

    },
    mounted() {
        console.log("mounted", this.inputstring);
        amplitude.track('Document Input mounted', { "Input": this.inputstring  });
        this.objective = this.inputstring;
    }
}


</script>

<style scoped>
.docinputgroup {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}

.AspectGroup {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-around;

}

.submitbutton {
    width: 100px;
    border-radius: 30px;
    background: #E76408;
    color: #FFF;
    margin-top: 1rem;
    align-self: center;
}

.label {
    margin-left: 0.5rem;
}

.inputlabel {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0px;
}
</style>