import { createApp } from 'vue'
import PrimeVue from 'primevue/config';
import App from './App.vue'
import ToastService from 'primevue/toastservice';
import VueGtag from "vue-gtag";
// import { createMetaManager } from 'vue-meta'
import DialogService from 'primevue/dialogservice';

import { createRouter, createWebHistory } from 'vue-router'
import ShowAdbyId from './components/ShowAdbyId.vue';
import ShowVidById from './components/ShowVidById.vue'; 
import ProductInput from './components/ProductInput.vue';
import dynamicAds from './components/dynamicAds.vue';
import ProcessPurchase from './components/ProcessPurchase.vue';
import Aura from '@primevue/themes/aura';



import 'bootstrap/dist/css/bootstrap.min.css';
import IQFrame from './components/IQFrame.vue';
import Tooltip from 'primevue/tooltip';


// import Lara from '@/presets/lara';
// import Wind from '@/presets/wind';

// import { createGtm } from '@gtm-support/vue-gtm';
// import router from './router';



const app= createApp(App);
const routes = [
  { path: '/', component: ProductInput},
  { path: '/adid/:adid', component: ShowAdbyId, props: true },
  { path: '/vidid/:videoid', component: ShowVidById,props: route => ({ videoid: route.params.videoid, type: route.query.type }) },
  { path: '/share/:videoid', component: IQFrame, props: route => ({ videoid: route.params.videoid, type: route.query.type }) },
  { path: '/dynamic/:productId', component: dynamicAds, props: true },
  { path: '/process', component: ProcessPurchase, props: true }
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

console.log("Router created",router.getRoutes())
app.use(router);
app.use(PrimeVue,{ inputStyle: "filled",theme: {
  preset: Aura
}});
app.use(ToastService);
app.use(DialogService);
app.directive('tooltip', Tooltip);




app.use(VueGtag, {
    config: { id: "GT-NGW6LZPT" }
  })

router.isReady().then(() => app.mount('#app'))
app.directive('tooltip', Tooltip);
