<template>
    <Message severity="info" v-if="this.imageurl.length>5" >
            <p>Sorry you have Insufficient tokens to complete this action.<br/>Choose one of the options below to continue </p>

        </Message>
    
    <div class="pricinggrid" v-if="this.products.length > 0">
        <!-- <div class="pbox"  v-for= "product in this.products" :key="product"> -->
            <Panel v-for= "(product, index) in this.products" :key="index" class="ppanel" :header="'Generate upto ' + product.adscount  + ' Ads'  + ' +  Unlock unlimited downloads '  " :style = "{'align-items' : 'center'}">
                <p class="tokenamt">  {{ product.tokens }} Tokens</p>
                <p class="dollaramt">  {{ product.price }} </p>
                <Button class="buybutton"  @click="buyToken(product.tokens,product.buttonid)"   label="Buy" />
                <img class="psupport" :src="require('@/assets/supportedpayments.png')" />
            </Panel>

        
    </div>

    <!-- <DataTable  :value="this.products">
        <Row field="tokens" header="Tokens">
            <template #body="slotProps">
            {{ (slotProps.data.tokens) }} tokens
        </template>
        </Row>
        <Row field="adscount" header="No. of Ads">
            <template #body="slotProps">
            Create upto {{ (slotProps.data.adscount) }} Ads
        </template>
        </Row> 
         <Row field="price" header="Price">
        <template #body="slotProps">
            {{ this.getPrice(slotProps.data.tokens) }} 
            
        </template>
    </Row>
        <Row field="buy" header="" class="buybutton">
        <template #body="slotProps">
            <Button  @click="buyToken(slotProps.data.tokens,slotProps.data.buttonid)"   label="Buy" />
        </template>
    </Row>

 


    </DataTable> -->

    <!-- <div class="pricinggrid">
        <div v-for= "product in this.products" :key="product">
            <stripe-buy-button   :buy-button-id="product.buttonid" publishable-key="pk_live_51P8ses01w3L9uiwUrmddVtDimzCcztrR6vNkbLPWrMS8URLa6FQeKzaebUoFjeJawym0UkqrdL3Wi7XWu9A7kgYp00BfkZEsgK"/>
        </div>

    </div> -->

    <Panel  v-if="showemailbox==true" >
        <Message severity="info" >
            <p>Sorry. We are unable to process payments at this time.<br/> <br/>To create more Ads, please provide your email and get  <b  style="color:yellow;">50 FREE tokens </b> (upto 10 Ads)  </p>
        </Message>

        <InputText class="iptext" v-model="userEmail" placeholder="Enter your email" />
        <Button   class= "submitbtn" @click="submitEmail"   label="Submit" />
        
    </Panel>


</template>

<script>
// import DataTable from 'primevue/datatable';
// import Column from 'primevue/column';
// import Row from 'primevue/row';
import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import * as amplitude from '@amplitude/analytics-browser';
// import ColumnGroup from 'primevue/columngroup';   // optional
// import Row from 'primevue/row';   
// import Tag from 'primevue/tag';   // optional
import Button from 'primevue/button';   // optional
// import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'   // optional




export default {
    name: 'PricingPage',
    components: {  Button,Panel,InputText,Message },
    emits:["emailsubmitted","buybuttonclicked"],
    props:["downloadurl","userCountry","userid"],
    data() {
        return {
        
        products : [{tokens:50,adscount:10,price:499,buy:"Buy",buttonid:"aEU15u2nu3px2768wB"},{tokens:250,adscount:50,price:1999,buy:"Buy",buttonid:"4gw7tS5zG2ltdPO28c"},{tokens:500,adscount:100,price:2499,buy:"Buy",buttonid:"fZe6pOfage4b9zy6ou"}],
        detectedCountry:true,
        showemailbox:false,
        userEmail:"",
        imageurl:""
            
        
    }
},
    methods: {  

        getCountry() {
              
            //   fetch("https://api.ipify.org/?format=json").then(response =>  response.json()).then(data => {
            //     console.log(data.ip);
                
            //     fetch("http://ip-api.com/json/"+data.ip+"?fields=countryCode").then(response => response.json()).then(rdata => {
            //         console.log("country code after fetch  is " ,rdata.countryCode);
            //         // this.userCountry=rdata.countryCode;
            //         this.detectedCountry=true;
            //         this.products.forEach(product => {
            //             product.price=this.getPrice(product.tokens); })
                      
                   
                   
            //     })
            //   })

            this.products.forEach(product => {
                product.price=this.getPrice(product.tokens); })

    
            },

        buyToken(tokenamt,buttonid) {
            amplitude.track('Buy Token', {tokenamt: tokenamt});
            // if (this.downloadurl !== undefined && this.downloadurl.length>5) {
            //     if ( this.downloadurl != "https://dummyimage" ){
            //     this.$emit('buybuttonclicked',this.downloadurl,true) }
            //     this.imageurl=""
                
            // }
            window.open("https://buy.stripe.com/"+buttonid+"?client_reference_id="+this.userid+"&utm_content=" + this.userid + "_" + "somedownloadidurl", "_blank", "_blank")
            console.log(buttonid)
            // window.open("https://checkout.acuit.ai/b/test_5kA5mQ8jC6gX1sQ7ss"+"?client_reference_id="+this.userid+"&utm_content=" + this.userid + "_" + "somedownloadidurl", "_blank") // for testing
            
            
            
            // console.log("buying token ",tokenamt);
            

            
        },
        submitEmail(){
            console.log("email submitted ",this.userEmail);
            this.$emit('emailsubmitted',this.userEmail);
            amplitude.track('Email Submitted from Pricing', {email: this.userEmail});
            
        },

         getPrice(tokenamt) {
            console.log("User Country is ",this.userCountry)
            if(this.userCountry=="IN" || this.userCountry=="PK" || this.userCountry === undefined) {
                if(tokenamt==50) {
                    return "₹499";
                }
                else if(tokenamt==250) {
                    return "₹1,999";
                }
                else if(tokenamt==500) {
                    return "₹3,499";
                }
            }
            else  {
                if(tokenamt==50) {
                    return "$2";
                }
                else if(tokenamt==250) {
                    return "$7";
                }
                else if(tokenamt==500) {
                    return "$12";
                }

        }
    }

        },
        mounted() {
            console.log("Country detected yet?",this.userCountry)
            this.imageurl=this.downloadurl;
            console.log("image url is ",this.imageurl);
            this.getCountry();
            amplitude.track('Pricing Page Loaded', {country: this.detectedCountry});
            
        },
        created() {
let stripescript = document.createElement('script');    stripescript.setAttribute('src',"https://js.stripe.com/v3/buy-button.js");
document.head.appendChild(stripescript);
}
    }


</script>
<style scoped>
.iptext {
    width: 50%;
    margin: 10px;
}
.buybutton {
    margin: 10px;
    height:2vh;
    width:2vw;
}
.submitbtn {
    margin: 10px;
}

.pricinggrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    margin: 10px;
}

.ppanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align:center;
    border-radius: 10px;
    /* background-color: #f0f0f0; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.buybutton {
    margin: 10px;
    height:5vh;
    width:20vw;
    border-radius: 10px;
}
.dollaramt {
    font-size: 30px;
    font-weight: bold;
    align-self: center;
    text-align: center;
    
}
.tokenamt {
    font-size: 20px;
    font-weight: bold;
    align-self: center;
    text-align: center;
    
}

.pbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    /* background-color: #f0f0f0; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #494545;
}

.psupport {

    margin: 2px;
    width:auto;
    height: 50px;
    align-self: center;
}
.p-panel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;;
    width:90%;
}


@media screen and (max-width: 768px) {
   
    .pricinggrid {
    display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ppanel {
    width: 90%;
    justify-content: center;
}
.buybutton {
    width: 60vw;
}

}




</style>