<template>
    <div class="dialog-content">
    <div class="leftpanel">
        <div class="titletext"> Better Inputs = Better Ads! </div>
        <br/><br/><br/>

        <Button label="Review and Edit Ad Details" @click="$emit('editprompter',true)" />
        <br/><br/><br/>
        <Button label="Continue without Editing" @click="$emit('editprompter',false)" />
        <br/><br/><br/>

    </div>
    <div class="rightpanel">
        <img  :src="require('@/assets/samplead.png')" alt="ad" style="width:80%;height:80%;object-fit:contain;margin-top:1rem"/>
    </div>
</div>
  </template>
  
  <script>
import Button from 'primevue/button';




  export default {
    name: 'EditPrompter',
    components: {
        Button
      // Your components go here
    },
    emits: ["editprompter"],
    data() {
      return {
        // Your data properties go here
      };
    },
    methods: {
      // Your methods go here
    },
    computed: {
      // Your computed properties go here
    },
  };
  </script>
  
  <style scoped>
.leftpanel {
    width: 50%;
    float: left;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height:100%;
}

.rightpanel {
    width: 50%;
    float: right;
    height: 40vh;
    padding: 20px;
    
}

.titletext {
    margin-top: 2rem;
    font-size: 30px;
    font-weight: bold;
    color: #ede4e4;
    text-align: center;
}
.button {
    width: 100%;
    height: 5rem;
    background-color: #f5f5f5;
    border: none;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    margin-top: 10rem;
    margin-bottom: 10rem;
}

div.p-dialog-content {
    display: flex;
    height: 100%;
    width: 100%;
    
}

  </style>